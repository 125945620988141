<template>
    <div class="symbols-select">
        <div class="item"
             v-for="(symbol, i) in selectSymbols"
             :key="i"
             :class="{active: symbol.symbol === symbolActive}"
             @click.stop="makeActive(symbol.symbol)"
        >
            <img :src="symbol.icon" />
            <div class="name">{{ selectSymbols[symbol.symbol] ? selectSymbols[symbol.symbol].name : '' }}</div>
        </div>
    </div>
</template>

<script>
import {SELECT_SYMBOLS} from '@/common/config'

export default {
    name: 'CurrenciesSelect',
    props: ['initSymbol'],
    data() {
        return {
            selectSymbols: SELECT_SYMBOLS,
            symbolActive: 'YNP'
        }
    },
    methods: {
        makeActive(sym) {
            this.symbolActive = sym

            this.$emit('active', sym)
        }
    },
    watch: {
        initSymbol(val) {
            this.symbolActive = val
        }
    }
}
</script>

<style scoped>
    .symbols-select {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        border: 1px solid var(--border);
        padding: 5px 0;
        border-radius: 4px;
        position: absolute;
        z-index: 3;
        background: var(--background);
        top: 0;
        left: 0;
    }

    .item {
        display: flex;
        align-items: center;
        padding: 8px 20px;
        border-bottom: 1px solid var(--border);
    }

    .item:last-child {
        border-bottom: none;
    }

    .item.active, .item:hover {
        background: rgba(13,52,120,0.2);
    }

    .name {
        font-size: 1.2em;
        font-weight: 400;
    }

    img {
        height: 20px;
        width: 20px;
        margin-right: 10px;
    }
</style>