<template>
    <div class="register" :class="{hide: page !== 'forgot-password'}">
        <div class="cont">
            <div class="title">Reset Your Password</div>
            <div class="form-row">
                <label>E-mail address</label>
                <input v-model="email" />
            </div>
            <div class="form-row">
                <button @click="resetPassword">Reset Password</button>
            </div>
            <div class="login">
                <div>Go back to <span class="link" @click="openLogin">Log In</span></div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'
    import {PAGE} from '@/store/mutations.type'
    import {PASSWORD_RESTORE} from '@/store/actions.type'

    export default {
        name: "ForgotPassword",
        data() {
            return {
                email: ''
            }
        },
        methods: {
            openLogin() {
                this.$store.commit(PAGE, 'login')
            },
            resetPassword() {
                this.$store.dispatch(PASSWORD_RESTORE, {
                    email: this.email
                })

                this.$store.commit(PAGE, 'login')
            }
        },
        computed: {
            ...mapGetters(['page'])
        },
        watch: {
            page(val) {
                if (val === 'forgot-password') {
                    this.email = ''
                }
            }
        }
    }
</script>

<style scoped>
    .register {
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        overflow-y: scroll;
        z-index: 5;
        background: var(--background-dark);
        -webkit-box-shadow: inset 0px 0px 100px 0px rgba(255, 88, 88, 1);
        -moz-box-shadow: inset 0px 0px 100px 0px rgba(255,88,88,1);
        box-shadow: inset 0px 0px 100px 0px rgba(255,88,88,1);
    }

    .cont {
        background: var(--background);
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 450px;
        padding: 40px 25px;
        border-radius: 10px;
    }

    .title {
        font-size: 2em;
        font-weight: 400;
        margin-bottom: 30px;
    }

    .form-row {
        width: 100%;
        margin-bottom: 30px;
    }

    label {
        font-size: 1.2em;
    }

    input, textarea {
        border-radius: 5px;
        border: 1px solid rgba(0,0,0,0.2);
        padding: 10px;
    }

    textarea {
        resize: none;
        width: 100%;
        height: 100px;
        font-size: 1.4em;
        font-weight: 400;
        text-align: center;
        vertical-align: center;
    }

    button {
        background: var(--brand);
        border: 1px solid var(--brand);
        color: var(--background);
        font-size: 1.3em;
        font-weight: 500;
        border-radius: 5px;
    }

    .login {
        text-align: center;
        font-size: 1.2em;
        font-weight: 400;
    }

    .login .link {
        color: var(--font-blue);
        text-decoration: underline;
        cursor: pointer;
    }
</style>