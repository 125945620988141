<template>
    <div class="two-cols">
        <div class="onecol">
            <h4>Withdrawals history</h4>
            <div class="table-container">
                <table>
                    <thead>
                    <tr>
                        <th class="no-mobile">{{ tokens.ID }}</th>
                        <th>{{ tokens.Time }}</th>
                        <th>{{ tokens.Amount }}</th>
                        <th>{{ tokens.Currency }}</th>
                        <th>{{ tokens.Status }}</th>
                        <th class="no-mobile">{{ tokens.Info }}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <template inline-template>
                        <tr v-for="withdrawal in withdrawals"
                            v-bind:key="withdrawal.id"
                        >
                            <td class="no-mobile">{{withdrawal.id}}</td>
                            <td>{{withdrawal.timeCreatedString}}</td>
                            <td>{{withdrawal.amount.toLocaleString(undefined, { minimumFractionDigits: activeAccount.precision, maximumFractionDigits: activeAccount.precision })}}</td>
                            <td>{{withdrawal.currency}}</td>
                            <td v-bind:class="withdrawal.status == 1 ? 'green' : (withdrawal.status == 2 ? 'red' : '')">{{ ([tokens.StatusPending, tokens.StatusProcessed, tokens.StatusDeclined, tokens.StatusBeingProcessed])[withdrawal.status]}}</td>
                            <td class="no-mobile">{{withdrawal.info}}</td>
                        </tr>
                    </template>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
    name: "WithdrawalsTable",
    computed: {
        ...mapGetters(['withdrawals', 'activeAccount', 'tokens'])
    }
}
</script>

<style scoped>
    table {
        width: 100%;
        table-layout: fixed;
        border-collapse: collapse;
    }

    td, th {
        text-align: center;
        padding: 6px;
    }
</style>
