<template>
    <div class="two-cols">
        <div class="onecol">
            <h4>{{ tokens.UploadedDocuments }}</h4>
            <div class="table-container">
                <table>
                    <thead>
                    <tr>
                        <th>{{ tokens.Type }}</th>
                        <th>{{ tokens.TimeUploaded }}</th>
                        <th>{{ tokens.Status }}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <template inline-template>
                        <tr v-for="document in documents"
                            v-bind:key="document.id"
                        >
                            <td>{{ ([tokens.ProofOfId, tokens.ProofOfAddress])[document.type]}}</td>
                            <td>{{document.timeCreatedString}}</td>
                            <td v-bind:class="document.status == 1 ? 'green' : (document.status == 2 ? 'red' : '')">{{ ([tokens.StatusPending, tokens.StatusProcessed, tokens.StatusDeclined, tokens.StatusBeingProcessed])[document.status]}}</td>
                        </tr>
                    </template>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'

    export default {
        name: "DocumentsTable",
        computed: {
            ...mapGetters(['documents', 'tokens'])
        }
    }
</script>

<style scoped>
    table {
        width: 100%;
        table-layout: fixed;
        border-collapse: collapse;
    }

    td, th {
        text-align: center;
        padding: 6px;
    }
</style>
