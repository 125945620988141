<template>
    <div class="balances">
        <div class="title">
            <div>Total Balance</div>
            <div class="val">{{currencySign(displayCurrency)}}{{ total.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</div>
        </div>
        <div class="items">
            <div class="item"
                 v-for="account in balAccounts"
                 :key="account.id+account.product"
            >
                <div class="name">
                    <img class="icon" :src="'/images/crypto-icons/'+(account.currency === 'YNP' ? 'cny' : account.currency).toLowerCase()+'@2x.png'" />
                    <div>{{ account.alias }}</div>
                </div>
                <div class="row">
                    <div class="btn btn-primary nomobile" :class="{hide: account.alias !== 'BTC Wallet' || account.balance > 0}"  @click="changePage('deposit')">Deposit Funds</div>
                    <div class="col">
                        <div>{{currencySign(account.currency)}}{{ account.balance.toLocaleString(undefined, {minimumFractionDigits: account.precision, maximumFractionDigits: account.precision}) }}</div>
                        <div>{{currencySign(displayCurrency)}}{{ account.balanceUSD.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'
    import {currencySign} from '@/common/helpers'
    import {BLUR, MODAL, PAGE} from '@/store/mutations.type'

    export default {
        name: "Balances",
        computed: {
            ...mapGetters(['accounts', 'symbols', 'displayCurrency']),
            total() {
                let total = 0

                for(let n in this.accounts) {
                    let a = this.accounts[n]

                    if (a.product !== 'savings' || !a.alias) {
                        continue
                    }

                    total += this.symbols[a.currency+'USD'] ? a.balance * this.symbols[a.currency+'USD'].quote.bid : 0
                }

                if (this.displayCurrency !== 'USD') {
                    if (this.symbols[this.displayCurrency+'USD']) {
                        total /= this.symbols[this.displayCurrency+'USD'].quote.bid
                    } else if (this.symbols['USD'+this.displayCurrency]) {
                        total *= this.symbols['USD'+this.displayCurrency].quote.bid
                    }
                }

                return total
            },
            balAccounts() {
                let balAccounts = []

                for(let n in this.accounts) {
                    let a = this.accounts[n]

                    if (a.product !== 'savings' || !a.alias) {
                        continue
                    }

                    a.balanceUSD = this.symbols[a.currency+'USD'] ? a.balance * this.symbols[a.currency+'USD'].quote.bid : 0

                    if (this.displayCurrency !== 'USD') {
                        if (this.symbols[this.displayCurrency+'USD']) {
                            a.balanceUSD /= this.symbols[this.displayCurrency+'USD'].quote.bid
                        } else if (this.symbols['USD'+this.displayCurrency]) {
                            a.balanceUSD *= this.symbols['USD'+this.displayCurrency].quote.bid
                        }
                    }

                    balAccounts.push(a)
                }

                return balAccounts
            }
        },
        methods: {
            changePage(page) {
                this.$store.commit(PAGE, page)
            },
            showModal(modal) {
                this.$store.commit(MODAL, {
                    [modal]: true
                })
                this.$store.commit(BLUR, true)
            },
            currencySign
        }
    }
</script>

<style scoped>
    .balances {
        display: flex;
        flex-direction: column;
        border-radius: 5px;
        border: 1px solid var(--border);
        padding: 25px 15px;
        margin: 15px;
        align-items: stretch;
        flex: 1 0 45%
    }

    .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 15px;
    }

    .title div:first-child {
        color: var(--font-secondary);
        font-weight: 400;
        font-size: 1.3em;
    }

    .title .val {
        font-size: 2em;
        font-weight: 400;
    }

    .items {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        margin-top: 30px;
        padding: 0 15px;
    }

    .items .item {
        padding: 15px 0;
        border-bottom: 1px solid var(--border);
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .items .item:last-child {
        border-bottom: none;
    }

    .items .item .name {
        display: flex;
        align-items: center;
        font-size: 1.5em;
        font-weight: 400;
    }

    .items .item .name img {
        height: 30px;
        width: 30px;
        margin-right: 15px;
    }

    .items .item .btn {
        margin-right: 20px;
    }

    .items .item .col {
        align-items: flex-end;
    }

    .items .item .col div:first-child {
        margin-bottom: 5px;
        font-size: 1.2em;
        font-weight: 400;
    }
</style>