<template>
    <div class="header-content">
        <div class="header">
            <div class="row menu mobile">
                <div class="item">
                    <i class="material-icons" @click="menu = !menu">menu</i>
                </div>
            </div>
            <div class="row">
                <div class="logo nomobile">
                    <img src="@/assets/logo.png" />
                    <span>YuanPay Group</span>
                </div>
                <div class="menu">
                    <div class="item" @click="changePage('deposit')">
                        <i class="material-icons">download</i>
                        <span>Deposit</span>
                    </div>
                    <!--                <div class="item" @click="changePage('withdrawal')">-->
                    <!--                    <i class="material-icons">outbound</i>-->
                    <!--                    <span>Withdraw</span>-->
                    <!--                </div>-->
                    <div class="item" @click="changePage('staking')">
                        <i class="material-icons">group_work</i>
                        <span>Stake</span>
                    </div>
                </div>
            </div>
            <div class="row menu">
                <div class="item nomobile">
                    <i class="material-icons">account_circle</i>
                    <span>Welcome, {{ user.fullname }}</span>
                </div>
                <div class="item nomobile">
                    <i class="material-icons">security</i>
                    <span>Security code: {{ ('0000'+user.security_code).slice(-4) }}</span>
                </div>
                <div class="item">
                    <i class="material-icons" @click="logout">logout</i>
                </div>
            </div>
        </div>
        <div class="marquee">
            <marquee-text :repeat="5">
                <div class="marquee-content">
                    <div v-for="symbol in marquee"
                         :key="symbol"
                         class="marquee-item"
                    >
                        <span class="name">{{ symbols[symbol].alias }}</span>
                        <span class="price" :class="symbols[symbol].quote.changePct >= 0 ? 'green-back' : 'red-back'">{{ symbols[symbol].quote.bid.toFixed(symbols[symbol].precision) }}</span>
                        <span class="change" :class="symbols[symbol].quote.changePct >= 0 ? 'green' : 'red'">{{symbols[symbol].quote.changePct > 0 ? '+' : (0 > symbols[symbol].quote.changePct ? '-' : '')}}{{ Math.abs(symbols[symbol].quote.changePct).toFixed(2) }}%</span>
                    </div>
                </div>
            </marquee-text>
        </div>
        <SideMenu :class="{hide: !menu}" @pageChange="menu = false" />
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {BLUR, MODAL, PAGE} from '@/store/mutations.type'
import {LOGOUT} from '@/store/actions.type'
import SideMenu from '@/components/SideMenu'

export default {
    name: 'Header',
    components: {SideMenu},
    data() {
        return {
            menu: false
        }
    },
    methods: {
        changePage(page) {
            this.$store.commit(PAGE, page)
        },
        showModal(modal) {
            this.$store.commit(MODAL, {
                [modal]: true
            })
            this.$store.commit(BLUR, true)
        },
        logout() {
            this.$store.dispatch(LOGOUT)
            this.$store.commit(PAGE, 'login')
        }
    },
    computed: {
        ...mapGetters(['user', 'symbols']),
        marquee() {
            let m = ['YNPUSD', 'YNPEUR', 'BTCUSD', 'ETHUSD']

            for(let n in m) {
                if (this.symbols[m[n]] === undefined) {
                    return []
                }
            }

            return m
        }
    },
    watch: {
        menu(val) {
            console.log(val)
        }
    }
}
</script>

<style scoped>
    .header-content {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: stretch;
    }

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px 20px;
        width: 100%;
        background: var(--background-dark);
    }

    .marquee {
        height: 40px;
        display: flex;
        align-items: center;
    }

    .marquee-content {
        display: flex;
        align-items: center;
    }

    .marquee-item {
        display: flex;
        align-items: center;
        margin-right: 30px;
    }

    .marquee-item .name {
        font-size: 1.2em;
        font-weight: 400;
    }

    .marquee-item .price {
        font-size: 1.2em;
        font-weight: 400;
        color: var(--white);
        margin: 0 10px;
        border-radius: 3px;
        padding: 4px 10px;
    }

    .marquee-item .price.green-back {
        background: var(--green);
    }

    .marquee-item .price.red-back {
        background: var(--red);
    }

    .logo {
        display: flex;
        align-items: center;
    }

    .logo span {
        font-size: 1.5em;
        color: #ffffff;
        font-weight: 500;
        margin-right: 50px;
    }

    .logo img {
        height: 30px;
        margin-right: 10px;
    }

    .menu {
        display: flex;
        align-items: center;
    }

    .menu .item {
        padding: 8px 15px;
        cursor: pointer;
        border-right: 1px solid rgba(255,255,255,0.2);
        display: flex;
        align-items: center;
        color: var(--background);
        font-size: 1.2em;
        font-weight: 500;
    }

    .menu .item:last-child {
        border-right: none;
        margin-right: 0;
    }

    .menu .item i {
        font-size: 1.5em;
        margin-right: 8px;
    }

    .item img {
        height: 21px;
        width: 21px;
        margin-right: 8px;
    }

    @media (max-width: 1023px) {
        .row {
            flex-wrap: nowrap;
        }

        .header .row:first-child {
            justify-content: space-between;
            flex-wrap: nowrap;
        }

        .menu .item {
            padding: 0 10px 0 0;
            margin: 0 10px 0 0;
        }

        .menu .item:last-child {
            padding: 0;
            margin: 0;
        }

        .menu .item i {
            margin-right: 0;
        }
    }
</style>