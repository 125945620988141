<template>
    <div class="footer">

    </div>
</template>

<script>
export default {
name: "Footer"
}
</script>

<style scoped>
    .footer {
        flex: 0 0 30px;
        background: var(--background-dark);
    }
</style>