<template>
    <div class="chart">
        <div class="select" @click="selectActive = !selectActive">
            <img :src="selectSymbols[symbolActive] ? selectSymbols[symbolActive].icon : ''" />
            <div class="name">{{ (symbols[symbolActive] ? symbols[symbolActive].alias : '')+' ('+symbolActive+')' }}</div>
            <i class="material-icons">expand_more</i>
            <SymbolsSelect init-symbol="'YNPUSD" class="select-sym" @active="changeSymbol" :class="{hide: !selectActive}" />
        </div>
        <div class="current">Current Price</div>
        <div class="price">{{symbols[symbolActive] ? currencySign(symbols[symbolActive].base_currency) : '$'}}{{ symbols[symbolActive] ? Number(symbols[symbolActive].quote.bid).toLocaleString(undefined, {minimumFractionDigits: symbols[symbolActive] ? symbols[symbolActive].precision : 2, maximumFractionDigits: symbols[symbolActive] ? symbols[symbolActive].precision : 2}) : '0.00'}} <span class="change" :class="symbols[symbolActive] ? (symbols[symbolActive].quote.changePct > 0 ? 'green' : (symbols[symbolActive].quote.change < 0 ? 'red' : '')) : ''">{{symbols[symbolActive] ? (symbols[symbolActive].quote.changePct > 0 ? '+' : (0 > symbols[symbolActive].quote.change ? '-' : '')) : ''}}{{ symbols[symbolActive] ? Math.abs(symbols[symbolActive].quote.changePct).toFixed(2) : '0.00' }}%</span> </div>
        <div class="chartCont" ref="chart">
            <highcharts :options="chartOptions"></highcharts>
            <div class="frames">
                <div class="frame" v-on:click="activeFrame = 'day'" :class="{active: activeFrame === 'day'}">Day</div>
                <div class="frame" v-on:click="activeFrame = 'week'" :class="{active: activeFrame === 'week'}">Week</div>
                <div class="frame" v-on:click="activeFrame = 'month'" :class="{active: activeFrame === 'month'}">Month</div>
                <div class="frame nomobile" v-on:click="activeFrame = 'year'" :class="{active: activeFrame === 'year'}">Half Year</div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'
    import {CHART_SYMBOLS} from '@/common/config'
    import SymbolsSelect from '@/components/SymbolsSelect'
    import {SYMBOL_ACTIVE, TIMEFRAME} from '@/store/mutations.type'
    import {currencySign} from '@/common/helpers'

    export default {
        name: "Chart",
        components: {SymbolsSelect},
        mounted() {
            setInterval(() => {
                if (this.page !== 'dashboard') {
                    return
                }

                this.chartOptions.chart.width = this.$refs.chart.clientWidth
                this.chartOptions.chart.height = this.$refs.chart.clientHeight
            }, 1000)
        },
        data() {
            return {
                selectSymbols: CHART_SYMBOLS,
                selectActive: false,
                activeFrame: 'day',
                chartOptions: {
                    chart: {
                        backgroundColor: 'rgba(0,0,0,0)',
                        width: 1,
                        height: 1,
                        // margin: [0, 0, 0, 0]
                    },
                    title: false,
                    // tooltip: false,
                    legend: false,
                    credits: false,
                    xAxis: {
                        type: 'datetime',
                        tickInterval: 4 * 3600000
                    },
                    yAxis: {
                        title: false,
                        opposite: true
                    },
                    plotOptions: {
                        area: {
                            color: '#ff7600',
                            fillColor: {
                                linearGradient: {
                                    x1: 0,
                                    y1: 0,
                                    x2: 0,
                                    y2: 1
                                },
                                stops: [
                                    [0, 'rgba(255,118,0,0.7)'],
                                    [1, 'rgba(255,118,0,0.2)']
                                ]
                            }
                        }
                    },
                    series: [{
                        name: '',
                        type: 'area',
                        data: []
                    }]
                }
            }
        },
        methods: {
            changeSymbol(sym) {
                this.selectActive = false

                this.$store.commit(SYMBOL_ACTIVE, sym)
            },
            currencySign
        },
        computed: {
            ...mapGetters(['chartData', 'symbols', 'symbolActive', 'page'])
        },
        watch: {
            chartData(val) {
                if (val) {
                    let d = [],
                        max = null,
                        min = null

                    val = val.slice(-200)

                    for(let n in val) {
                        d.push([val[n].time * 1000, val[n].close])

                        if (max === null || val[n].close > max) {
                            max = val[n].close
                        }
                        if (min === null || val[n].close < min) {
                            min = val[n].close
                        }
                    }

                    this.chartOptions.series[0].data = d
                    this.chartOptions.yAxis.min = min * 0.9
                    this.chartOptions.yAxis.max = max * 1.1
                }
            },
            activeFrame(val) {
                let tframe = 'M15'

                switch (val) {
                    case 'day':
                        tframe = 'M5'
                        this.chartOptions.xAxis.tickInterval = 4 * 3600000
                        break
                    case 'week':
                        this.chartOptions.xAxis.tickInterval = 24 * 3600000
                        tframe = 'M30'
                        break
                    case 'month':
                        this.chartOptions.xAxis.tickInterval = 7 * 24 * 3600000
                        tframe = 'H4'
                        break
                    case 'year':
                        this.chartOptions.xAxis.tickInterval = 30 * 24 * 3600000
                        tframe = 'D1'
                        break
                }

                this.$store.commit(TIMEFRAME, tframe)
            },
            symbolActive(val) {
                if (!this.symbols[val]) {
                    return
                }

                this.chartOptions.series[0].name = this.symbols[val].alias
            }
        }
    }
</script>

<style scoped>
    .chart {
        display: flex;
        flex-direction: column;
        border-radius: 5px;
        border: 1px solid var(--border);
        padding: 25px 0 0;
        margin: 15px;
        align-items: center;
        flex: 1 0 45%
    }

    .select {
        display: flex;
        align-items: center;
        cursor: pointer;
        position: relative;
    }

    .select img {
        height: 22px;
        width: 22px;
        margin-right: 10px;
    }

    .select .name {
        font-size: 1.5em;
        font-weight: 500;
    }

    .select i {
        margin-left: 5px;
        font-size: 2em;
    }

    .current {
        margin-top: 20px;
    }

    .price {
        font-size: 3em;
        font-weight: 400;
        color: var(--font-blue);
        display: flex;
        align-items: center;
    }

    .price .change {
        font-size: 0.6em;
        margin-left: 15px;
    }

    .chartCont {
        width: 100%;
        flex: 1 1 auto;
        position: relative;
    }

    .frames {
        position: absolute;
        width: 150px;
        left: 50%;
        margin-left: -75px;
        top: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .frame {
        padding: 8px 20px;
        background: var(--font-blue);
        color: var(--background);
        margin-right: 15px;
        cursor: pointer;
        border-radius: 5px;
        border: 1px solid var(--font-blue);
        font-weight: 400;
        white-space: nowrap;
    }

    .frame:last-child {
        margin-right: 0;
    }

    .frame.active, .frame:hover {
        background: transparent;
        color: var(--font-blue);
    }

    .select-sym {
        top: 32px;
        left: 0;
        width: 100%;
    }
</style>