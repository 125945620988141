<template>
    <div class="two-cols">
        <div class="onecol">
            <h4>Transactions History</h4>
            <div class="table-container">
                <table>
                    <thead>
                    <tr>
                        <th class="no-mobile">{{ tokens.ID }}</th>
                        <th>{{ tokens.Account }}</th>
                        <th>{{ tokens.Type }}</th>
                        <th>{{ tokens.Time }}</th>
                        <th>{{ tokens.Amount }}</th>
                        <th>{{ tokens.Currency }}</th>
                        <th>{{ tokens.Status }}</th>
                        <th class="no-mobile">{{ tokens.Info }}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <template inline-template>
                        <tr v-for="deposit in transactions"
                            v-bind:key="deposit.id"
                        >
                            <td class="no-mobile">{{deposit.id}}</td>
                            <td>{{accountsIndex[deposit.account_id] ? accountsIndex[deposit.account_id].alias : deposit.account_id}}</td>
                            <td>{{deposit.type === 1 ? 'Deposit' : (deposit.type === 2 ? 'Withdrawal' : (deposit.type === 3 ? 'Transfer' : ''))}}</td>
                            <td>{{deposit.timeCreatedString}}</td>
                            <td>{{(deposit.conversion * deposit.amount).toLocaleString(undefined, { minimumFractionDigits: accountsIndex[deposit.account_id] ? accountsIndex[deposit.account_id].precision : activeAccount.precision, maximumFractionDigits: accountsIndex[deposit.account_id] ? accountsIndex[deposit.account_id].precision : activeAccount.precision })}}</td>
                            <td>{{accountsIndex[deposit.account_id] ? accountsIndex[deposit.account_id].currency : deposit.currency}}</td>
                            <td v-bind:class="deposit.status == 1 ? 'green' : (deposit.status == 2 ? 'red' : '')">{{ ([tokens.StatusPending, tokens.StatusProcessed, tokens.StatusDeclined, tokens.StatusBeingProcessed])[deposit.status]}}</td>
                            <td class="no-mobile">{{deposit.info}}</td>
                        </tr>
                    </template>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'

    export default {
        name: "TransactionsTable",
        computed: {
            ...mapGetters(['transactions', 'activeAccount', 'tokens', 'accounts']),
            accountsIndex() {
                let ret = {}

                for(let n in this.accounts) {
                    if (this.accounts[n].product !== 'savings') {
                        continue
                    }

                    ret[this.accounts[n].id] = this.accounts[n]
                }

                return ret
            }
        }
    }
</script>

<style scoped>
    table {
        width: 100%;
        table-layout: fixed;
        border-collapse: collapse;
    }

    td, th {
        text-align: center;
        padding: 6px;
    }
</style>
