<template>
    <div class="chat">
        <div class="form-row" :class="{hide: collapsed}">
            <textarea v-model="message" @keyup.enter="sendMessage"/>
            <button class="btn btn-primary" @click="sendMessage"><i class="material-icons">send</i> Send</button>
        </div>
        <div class="scroll-container" id="chat-messages" :class="{hide: collapsed}">
            <div v-for="message in supportMessages"
                 :key="message.id"
                 :class="message.user_id !== user.id ? 'from-manager' : 'from-client'"
                 class="message"
            >
                <div class="box">
                    <span class="text">{{ message.text }}</span>
                    <span class="time">{{ message.manager_id ? 'Agent' : user.fullname }} - {{ timeToString(message.time_created*1000).split(' ')[1]+' '+timeToString(message.time_created*1000).split(' ')[0].slice(0,5) }}</span>
                </div>
            </div>
        </div>
        <div class="chat-header" v-on:click="collapsed = !collapsed" :class="{collapsed: collapsed}">
            <span>Chat with Agent</span>
            <div class="row">
                <i class="material-icons unread" :class="{hide: !supportUnread}">radio_button_checked</i>
                <i class="material-icons">{{ collapsed ? 'expand_less' : 'expand_more' }}</i>
            </div>
        </div>
    </div>
</template>

<script>
import {SUPPORT_READ, SUPPORT_SEND} from '@/store/actions.type'
import {mapGetters} from "vuex";
import {timeToString} from "@/common/helpers";

export default {
    name: "chat",
    mounted() {
        setInterval(() => {
            if (!this.collapsed) {
                this.$store.dispatch(SUPPORT_READ)
            }
        }, 3000)
    },
    data() {
        return {
            message: '',
            collapsed: true
        }
    },
    methods : {
        sendMessage() {
            if (!this.message) {
                return
            }
            this.$store.dispatch(SUPPORT_SEND, this.message)
            this.message = ''
        },
        timeToString
    },
    computed: {
        ...mapGetters(['tokens', 'supportMessages', 'user', 'supportUnread'])
    },
    watch: {
        supportMessages() {
            setTimeout(() => {
                let container = document.getElementById("chat-messages")
                container.scrollTop = container.scrollHeight
            }, 300)
        },
        collapsed() {
            setTimeout(() => {
                let container = document.getElementById("chat-messages")
                container.scrollTop = container.scrollHeight
            }, 300)
        }
    }
}
</script>

<style scoped>
    .chat {
        position: fixed;
        bottom: 0;
        right: 30px;
        background: var(--background);
        max-height: 50%;
        display: flex;
        flex-direction: column-reverse;
        border: 1px solid var(--background-dark);
        border-radius: 10px 10px 0 0;
        overflow: hidden;
        width: 350px;
    }

    .chat-header {
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 10px;
        justify-content: space-between;
        font-size: 1.3em;
        border-bottom: 1px solid var(--font);
    }

    .chat-header.collapsed {
        border-bottom: none
    }

    .scroll-container {
        overflow-y: scroll;
        flex: 1 1 auto;
        padding: 0 20px;
    }

    .form-row {
        margin: 15px;
        flex: 0 0 50px;
        display: flex;
        align-items: stretch;
        flex-direction: row !important;
    }

    textarea {
        flex: 1 1 auto;
        height: auto;
    }

    .btn {
        margin-left: 20px;
        flex: 0 0 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    
    .message {
        display: flex;
        width: 100%;
    }

    .from-client {
        justify-content: flex-end;
    }

    .from-manager {
        justify-content: flex-start;
    }

    .from-manager .time {
        justify-content: flex-start;
    }

    .box {
        max-width: 90%;
        display: flex;
        flex-direction: column;
        margin: 2px;
        background-color: var(--background);
        border-radius: 3px;
        min-width: 140px;
    }

    .text {
        padding: 10px 0 0 10px;
    }

    .from-manager .text {
        padding: 10px 10px 0 0;
    }

    .from-client {
        text-align: right;
    }

    .from-manager {
        text-align: left;
    }

    .time {
        display: flex;
        justify-content: flex-end;
        font-size: 0.7em;
        padding: 3px;
        color: var(--font-secondary);
    }

    .unread {
        color: var(--brand);
        font-size: 1.2em;
        margin-right: 10px;
    }

    textarea {
        resize: none;
    }
</style>
