<template>
    <div class="welcome" :class="{hide: !modals.welcome}">
        <div class="cont">
            <div class="title">CLAIM 100 FREE YUAN COINS</div>
            <div class="form-row">
                <p>Congratulations! You have registered your interest with Yuan Pay Group earlier and have been selected to receive a gift of 100 Yuan Coins.<br/><br/>The coins will be deposited to your wallet and you can either keep them or use them for staking. We congratulate you on choosing Yuan Pay Group as your secure investment partner.</p>
            </div>
            <div class="form-row">
                <button class="btn btn-primary" @click="openDashboard">CLAIM FREE COINS</button>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'
    import {BLUR, MODAL} from '@/store/mutations.type'

    export default {
        name: 'Welcome',
        computed: {
            ...mapGetters(['modals'])
        },
        methods: {
            openDashboard() {
                this.$store.commit(MODAL, {
                    welcome: false
                })
                this.$store.commit(BLUR, false)

                window.history.pushState({}, '', '/')
            }
        }
    }
</script>

<style scoped>
    .welcome {
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        overflow-y: scroll;
        z-index: 5;
        background: rgba(246, 136, 47, 0.1);
    }

    .cont {
        background: var(--background);
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 450px;
        padding: 40px 25px;
        border-radius: 10px;
        border: 1px solid var(--border);
    }

    .title {
        font-size: 2em;
        font-weight: 400;
        margin-bottom: 30px;
    }

    p {
        font-size: 1.2em;
        line-height: 1.5;
    }

    .btn {
        margin-top: 20px;
    }
</style>