<template>
    <div class="staking">
        <div class="two-cols">
            <div class="onecol">
                <div class="row align-flex-end">
                    <h4>Flexible Farming</h4>
                    <span class="about">Flexible to Transfer and Safe to Earn</span>
                </div>
                <table>
                    <thead>
                    <tr>
                        <th>Coin</th>
                        <th>Annualized Interest Rate</th>
                        <th></th>
                    </tr>
                    </thead>
                </table>
                <div class="staking-row"
                     v-for="staking in stakingFlexible"
                     :key="staking.id"
                >
                    <table>
                        <tbody>
                        <tr>
                            <td>
                                <div class="row name">
                                    <img :src="staking.icon" />
                                    <span>{{ symbols[staking.currency+'USD'] ? symbols[staking.currency+'USD'].alias : staking.currency }}</span>
                                </div>
                            </td>
                            <td>
                                {{ staking.interest }}%
                            </td>
                            <td>
                                <button class="btn btn-primary" @click.stop="showFlexibleModal(staking.id)">Transfer</button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <div class="table-container">
                        <table
                            :class="{hide: stakingAccounts.flexible[staking.id].length === 0}"
                            class="positions"
                        >
                            <thead>
                            <tr>
                                <th>Staking ID</th>
                                <th>Size</th>
                                <th>Profit</th>
                                <th>Interest</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr
                                v-for="account in stakingAccounts.flexible[staking.id]"
                                :key="account.id"
                            >
                                <td class="name">
                                    Staking #{{ account.hash_id }}
                                </td>
                                <td>
                                    {{ account.currency }} {{ account.balance.toFixed(account.precision) }}
                                </td>
                                <td>
                                    {{ account.staking_currency }} {{ account.earned }}
                                </td>
                                <td :class="{promo: account.staking_currency === 'YNP'}">
                                    {{ account.interest.toFixed(2) }}%
                                </td>
                                <td>
                                    <button class="btn btn-brand" @click="closeStaking(account.id)">Receive</button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="pad"></div>
                <div class="row align-flex-end">
                    <h4>Fixed Staking</h4>
                    <span class="about">Flexible deposits, higher profits</span>
                </div>
                <div class="table-container">
                    <table style="width: 1000px !important;">
                        <thead>
                        <tr>
                            <th>Coin</th>
                            <th>Annualized Interest Rate</th>
                            <th>Duration (days)</th>
                            <th>Interest Per Lot</th>
                            <th></th>
                        </tr>
                        </thead>
                    </table>
                    <div class="staking-row"  style="width: 1000px"
                     v-for="staking in stakingFixed"
                     :key="staking.id"
                >
                    <table>
                        <tbody>
                        <tr>
                            <td>
                                <div class="row name">
                                    <img :src="staking.icon" />
                                    <span>{{ symbols[staking.currency+'USD'] ? symbols[staking.currency+'USD'].alias : staking.currency }}</span>
                                </div>
                            </td>
                            <td>
                                {{ staking.interests[periodChosen[staking.id]] }}%
                            </td>
                            <td>
                                <div class="periods">
                                    <div class="item"
                                         v-for="(period, i) in staking.periods"
                                         :key="period"
                                         :class="{active: periodChosen[staking.id] === parseInt(i)}"
                                         @click.stop="periodChosen[staking.id] = parseInt(i)"
                                    >{{ period }}</div>
                                </div>
                            </td>
                            <td>
                                {{ (staking.lot * staking.interests[periodChosen[staking.id]] * staking.periods[periodChosen[staking.id]] / (100 * 365)).toFixed(4) }} {{staking.currency}}
                            </td>
                            <td>
                                <button class="btn btn-primary" @click.stop="showFixedModal(staking.id, periodChosen[staking.id])">Transfer</button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <div class="table-container">
                        <table
                            :class="{hide: stakingAccounts.fixed[staking.id].length === 0}"
                            class="positions"
                        >
                            <thead>
                            <tr>
                                <th>Staking ID</th>
                                <th>Size</th>
                                <th>Profit</th>
                                <th>Money Release In</th>
                                <th>Interest</th>
                                <th/>
                            </tr>
                            </thead>
                            <tbody>
                            <tr
                                v-for="account in stakingAccounts.fixed[staking.id]"
                                :key="account.id"
                            >
                                <td class="name">
                                    Staking #{{ account.hash_id }}
                                </td>
                                <td>
                                    {{ account.currency }} {{ account.balance.toFixed(account.precision) }}
                                </td>
                                <td>
                                    {{ account.staking_currency }} {{ account.earned }}
                                </td>
                                <td>
                                    {{ account.days_left }} days
                                </td>
                                <td :class="{promo: account.staking_currency === 'YNP'}">
                                    {{ account.interest.toFixed(2) }}%
                                </td>
                                <td>
                                    <button class="btn btn-brand" @click="showFixedInterruptModal(account.id, staking.id)">Receive</button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                </div>
                <div class="pad"></div>
                <div class="row align-flex-end">
                    <h4>Activities</h4>
                    <span class="about">Investments in top companies</span>
                </div>
                <div class="activities-container">
                    <div
                        v-for="activity in activities"
                        :key="activity.id"
                        class="activity"
                        :class="{sold: soldOutList.indexOf(activity.id) === -1}"
                    >
                        <div class="row between">
                            <div class="row">
                                <img :src="activity.image ? activitiesStaticBase+activity.image : ''" :class="{hide: !activity.image}" />
                                <div class="col">
                                    <div class="activity-title">{{ activity.name }}</div>
                                    <div class="activity-description">{{ activity.description }}</div>
                                </div>
                            </div>
                            <div class="col align-flex-end">
                                <div class="activity-description">Target Amount</div>
                                <div class="activity-target">元{{ activity.amount.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0}) }}</div>
                                <div class="activity-description pad-top">Minimal Investment</div>
                                <div class="activity-target">元{{ activity.investment_min.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0}) }}</div>
                            </div>
                        </div>
                        <div class="row between">
                            <div class="annual-pct"><span class="grey">Annual IR:</span> {{ activity.pct_annual.toFixed(2) }}%</div>
                            <button class="btn btn-primary" :class="{hide: activityInvestments[activity.id] !== undefined}" @click="requestOffer(activity.id)">Request Offer</button>
                            <span class="brand" :class="{hide: !(activityInvestments[activity.id] !== undefined && activityInvestments[activity.id].status === 0)}" >Request Sent</span>
                            <span class="brand" :class="{hide: !(activityInvestments[activity.id] !== undefined && activityInvestments[activity.id].status === 2)}" >Not Available</span>
                            <span class="brand" :class="{hide: !(activityInvestments[activity.id] !== undefined && activityInvestments[activity.id].investment > 0)}" >Invested 元{{activityInvestments[activity.id] !== undefined ? activityInvestments[activity.id].investment.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) : 0}}</span>
                            <button class="btn btn-primary" :class="{hide: !(activityInvestments[activity.id] !== undefined && activityInvestments[activity.id].status === 1 && activityInvestments[activity.id].investment === 0)}" @click="showActivitiesModal(activity.id)">Invest</button>
                        </div>
                        <div class="sold-out" :class="{hide: soldOutList.indexOf(activity.id) !== -1}">
                            [ [ SOLD OUT ] ]
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-wrapper"
             :class="{hide: !activitiesModal}"
             @click.stop="activitiesModal = false">
            <div class="modal" @click.stop>
                <div class="title">
                    <span>{{ activities[activitiesModalId] ? activities[activitiesModalId].name : '' }} Activity</span>
                    <i class="material-icons" @click="activitiesModal = false">close</i>
                </div>
                <div class="form-row">
                    <label>
                        <span>
                            Subscription amount
                        </span>
                        <span>
                            <span class="grey">Available: </span>
                            <span class="val">{{currencySign('YNP')}}{{ wallets['YNP'].balance.toFixed(wallets['YNP'].precision) }}</span>
                        </span>
                    </label>
                    <div class="input">
                        <img :src="activities[activitiesModalId] && activities[activitiesModalId].image ? activitiesStaticBase+activities[activitiesModalId].image : ''" />
                        <input v-model="amount" />
                        <div class="max"
                             @click="amount = wallets['YNP'].balance.toFixed(wallets['YNP'].precision)"
                             >MAX</div>
                        <div class="currency">YNP</div>
                    </div>
                </div>
                <div class="info-row">
                    <span class="grey">Value date</span>
                    <span class="val">{{ timeToString(time * 1000) }}</span>
                </div>

                <div class="info-row">
                    <span class="grey">Activity Annual IR</span>
                    <span class="val green">{{activities[activitiesModalId] ? activities[activitiesModalId].pct_annual.toFixed(2) : 0}}%</span>
                </div>
                <div class="info-row">
                    <span class="grey">Your Annual IR</span>
                    <span class="val green">{{activityInvestments[activitiesModalId] ? activityInvestments[activitiesModalId].pct_annual.toFixed(2) : 0 }}%</span>
                </div>
                <div class="info-row">
                    <span class="grey">Minimal Investment</span>
                    <span class="val">{{currencySign('YNP')}}{{activities[activitiesModalId] ? activities[activitiesModalId].investment_min.toFixed(2) : 0 }}</span>
                </div>
                <div class="info-row">
                    <span class="grey">Investment Limit</span>
                    <span class="val">{{currencySign('YNP')}}{{activityInvestments[activitiesModalId] ? activityInvestments[activitiesModalId].investment_limit.toFixed(2) : 0 }}</span>
                </div>
                <div class="form-row terms-row">
                    <input type="checkbox" v-model="agree" class="check" />
                    <div class="terms">I have read and I agree to <a href="https://yuanpaygroup.com/pages/terms.html" target="_blank">Terms and Conditions</a></div>
                </div>
                <div class="form-row">
                    <button class="btn" :class="agree && amount > 0 ? 'btn-primary' : 'btn-disabled'" @click="investActivity">Transfer confirmed</button>
                </div>
            </div>
        </div>
        <div class="modal-wrapper"
             :class="{hide: !flexibleModal}"
             @click.stop="flexibleModal = false">
            <div class="modal" @click.stop>
                <div class="title">
                    <span>Transfer {{ stakingFlexible[flexibleModalId].currency }}</span>
                    <i class="material-icons" @click="flexibleModal = false">close</i>
                </div>
                <div class="form-row">
                    <label>
                        <span>
                            Subscription amount
                        </span>
                        <span>
                            <span class="grey">Available: </span>
                            <span class="val">{{currencySign(stakingFlexible[flexibleModalId].currency)}}{{ wallets[stakingFlexible[flexibleModalId].currency].balance.toFixed(wallets[stakingFlexible[flexibleModalId].currency].precision) }}</span>
                        </span>
                    </label>
                    <div class="input">
                        <img :src="stakingFlexible[flexibleModalId].icon"/>
                        <input v-model="amount" type="text" inputmode="decimal" />
                        <div class="max"
                             @click="amount = (Math.floor(wallets[stakingFlexible[flexibleModalId].currency].balance * Math.pow(10, wallets[stakingFlexible[flexibleModalId].currency].precision) + Number.EPSILON) / Math.pow(10, wallets[stakingFlexible[flexibleModalId].currency].precision) + Number.EPSILON).toFixed(wallets[stakingFlexible[flexibleModalId].currency].precision)"
                             >MAX</div>
                        <div class="currency">{{ stakingFlexible[flexibleModalId].currency }}</div>
                    </div>
                </div>
                <div class="form-row" :class="{hide: stakingFlexible[flexibleModalId].currency === 'YNP'}">
                    <label>Choose Payout Currency</label>
                    <div class="toggler">
                        <div class="item" :class="{active: stakingCurrency === stakingFlexible[flexibleModalId].currency}" @click="stakingCurrency = stakingFlexible[flexibleModalId].currency">
                            {{ stakingFlexible[flexibleModalId].currency }}</div>
                        <div class="item brand" :class="{active: stakingCurrency === 'YNP'}" @click="stakingCurrency = 'YNP'">Get 30% more with YNP Payout!</div>
                    </div>
                </div>
                <div class="info-row">
                    <span class="grey">Value date</span>
                    <span class="val">{{ timeToString(time * 1000) }}</span>
                </div>
                <div class="info-row">
                    <span class="grey">7-Day APY</span>
                    <span class="val green">{{currencySign(stakingFlexible[flexibleModalId].currency)}}{{ (stakingCurrency === 'YNP' ? stakingFlexible[flexibleModalId].interestYNP : stakingFlexible[flexibleModalId].interest).toFixed(2) }}%</span>
                </div>
                <div class="form-row terms-row">
                    <input type="checkbox" v-model="agree" class="check" />
                    <div class="terms">I have read and I agree to <a href="https://yuanpaygroup.com/pages/terms.html" target="_blank">Terms and Conditions</a></div>
                </div>
                <div class="form-row">
                    <button class="btn" :class="agree && amount > 0 ? 'btn-primary' : 'btn-disabled'" @click="makeStaking('flexible')">Transfer confirmed</button>
                </div>
            </div>
        </div>
        <div class="modal-wrapper"
             :class="{hide: !fixedModal}"
             @click.stop="fixedModal = false">
            <div class="modal modal-fixed" @click.stop>
                <div class="title">
                    <span>Transfer {{ stakingFixed[fixedModalId].currency }}</span>
                    <i class="material-icons" @click="fixedModal = false">close</i>
                </div>
                <div class="row stretch between">
                    <div class="col">
                        <div class="form-row">
                            <label class="font-12">Coin</label>
                            <span class="val font-15">{{ stakingFixed[fixedModalId].currency }}</span>
                        </div>
                        <div class="form-row">
                            <label class="font-12">Activity Duration (days)</label>
                            <div class="periods">
                                <div class="item"
                                     v-for="(period, i) in stakingFixed[fixedModalId].periods"
                                     :key="period"
                                     :class="{active: periodChosen[stakingFixed[fixedModalId].id] === parseInt(i)}"
                                     @click.stop="periodChosen[stakingFixed[fixedModalId].id] = parseInt(i)"
                                >{{ period }}</div>
                            </div>
                        </div>
                        <div class="form-row" :class="{hide: stakingFixed[fixedModalId].currency === 'YNP'}">
                            <label>Choose Payout Currency</label>
                            <div class="toggler">
                                <div class="item" :class="{active: stakingCurrency === stakingFixed[fixedModalId].currency}" @click="stakingCurrency = stakingFixed[fixedModalId].currency">
                                    {{ stakingFixed[fixedModalId].currency }}</div>
                                <div class="item brand" :class="{active: stakingCurrency === 'YNP'}" @click="stakingCurrency = 'YNP'">Get 30% more with YNP Payout!</div>
                            </div>
                        </div>
                        <div class="form-row">
                            <label>
                                <span>
                                    Lot amount
                                </span>
                                        <span>
                                    <span class="grey">Available: </span>
                                    <span class="val">{{ Math.floor(wallets[stakingFixed[fixedModalId].currency].balance / stakingFixed[fixedModalId].lot) }} Lots</span>
                                </span>
                            </label>
                            <div class="input">
                                <img :src="stakingFixed[fixedModalId].icon"/>
                                <input v-model="amount" />
                                <div class="max"
                                     @click="amount = Math.floor(wallets[stakingFixed[fixedModalId].currency].balance / stakingFixed[fixedModalId].lot)"
                                >MAX</div>
                                <div class="currency">Lots</div>
                            </div>
                            <div class="font-15 val">
                                = {{ amount * stakingFixed[fixedModalId].lot }} {{ stakingFixed[fixedModalId].currency }}
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="info-row">
                            <span class="grey">Lot Size</span>
                            <span class="val">{{ stakingFixed[fixedModalId].lot.toLocaleString(undefined, {minimumFractionDigits: wallets[stakingFixed[fixedModalId].currency].precision, maximumFractionDigits: wallets[stakingFixed[fixedModalId].currency].precision}) }} {{stakingFixed[fixedModalId].currency}}</span>
                        </div>
                        <div class="info-row">
                            <span class="grey">Interest per lot</span>
                            <span class="val">{{ (stakingFixed[fixedModalId][stakingCurrency === 'YNP' ? 'interestsYNP' : 'interests'][periodChosen[stakingFixed[fixedModalId].id]] * stakingFixed[fixedModalId].lot * stakingFixed[fixedModalId].periods[periodChosen[stakingFixed[fixedModalId].id]] / (100 * 365)).toFixed(wallets[stakingFixed[fixedModalId].currency].precision) }}  {{stakingFixed[fixedModalId].currency}} ({{ stakingFixed[fixedModalId][stakingCurrency === 'YNP' ? 'interestsYNP' : 'interests'][periodChosen[stakingFixed[fixedModalId].id] || 0].toFixed(2) }}% Annualized)</span>
                        </div>
                        <div class="info-row">
                            <span class="grey">Value date</span>
                            <span class="val">{{ timeToString(time * 1000) }}</span>
                        </div>
                        <div class="info-row">
                            <span class="grey">Redemtion date</span>
                            <span class="val">{{ timeToString(time * 1000 + stakingFixed[fixedModalId].periods[periodChosen[stakingFixed[fixedModalId].id]] * 86400000) }}</span>
                        </div>
                        <div class="info-row">
                            <span class="grey">Expected interest</span>
                            <span class="val">{{ (amount * stakingFixed[fixedModalId][stakingCurrency === 'YNP' ? 'interestsYNP' : 'interests'][periodChosen[stakingFixed[fixedModalId].id]] * stakingFixed[fixedModalId].lot * stakingFixed[fixedModalId].periods[periodChosen[stakingFixed[fixedModalId].id]] / (100 * 365)).toFixed(wallets[stakingFixed[fixedModalId].currency].precision) }} {{stakingFixed[fixedModalId].currency}}</span>
                        </div>
                        <div class="form-row terms-row">
                            <input type="checkbox" v-model="agree" class="check" />
                            <div class="terms">I have read and I agree to <a href="https://yuanpaygroup.com/pages/terms.html" target="_blank">Terms and Conditions</a></div>
                        </div>
                        <div class="form-row flex-end">
                            <button class="btn" :class="agree && amount > 0 ? 'btn-primary' : 'btn-disabled'" @click="makeStaking('fixed')">Transfer confirmed</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-wrapper"
             :class="{hide: !fixedInterruptModal}"
             @click.stop="fixedInterruptModal = false">
            <div class="modal modal-interrupt" @click.stop>
                <div class="title">
                    <span>Receive Fixed Staking</span>
                    <i class="material-icons" @click="fixedInterruptModal = false">close</i>
                </div>
                <div class="col">
                    <div class="info-row">
                        <span class="grey">Staking Size</span>
                        <span class="val">{{ stakingAccounts.all[fixedInterruptModalId] ? fixedInterruptModalNumbers.balance+' '+stakingAccounts.all[fixedInterruptModalId].currency : '' }}</span>
                    </div>
                    <div class="info-row">
                        <span class="grey">Value date</span>
                        <span class="val">{{ timeToString(stakingAccounts.all[fixedInterruptModalId] ? stakingAccounts.all[fixedInterruptModalId].created * 1000 : 0) }}</span>
                    </div>
                    <div class="info-row">
                        <span class="grey">Redemtion date</span>
                        <span class="val">{{ timeToString(time * 1000) }}</span>
                    </div>
                    <div class="info-row">
                        <span class="grey">Earned interest</span>
                        <span class="val">{{ fixedInterruptModalNumbers.earned }} {{stakingAccounts.all[fixedInterruptModalId] ? stakingAccounts.all[fixedInterruptModalId].currency : ''}}</span>
                    </div>
                    <div class="info-row">
                        <span class="grey">Early Redeem Penalty (30%)</span>
                        <span class="val brand">{{ fixedInterruptModalNumbers.penalty }} {{stakingAccounts.all[fixedInterruptModalId] ? stakingAccounts.all[fixedInterruptModalId].currency : ''}}</span>
                    </div>
                    <div class="info-row">
                        <span class="grey">Final amount</span>
                        <span class="val">{{ stakingAccounts.all[fixedInterruptModalId] ? (fixedInterruptModalNumbers.earned - fixedInterruptModalNumbers.penalty).toFixed(stakingAccounts.all[fixedInterruptModalId].precision) : '' }} {{stakingAccounts.all[fixedInterruptModalId] ? stakingAccounts.all[fixedInterruptModalId].currency : ''}}</span>
                    </div>
                    <div class="form-row terms-row">
                        <input type="checkbox" v-model="agree" class="check" />
                        <div class="terms">I have read and I agree to <a href="https://yuanpaygroup.com/pages/terms.html" target="_blank">Terms and Conditions</a></div>
                    </div>
                    <div class="form-row flex-end">
                        <button class="btn" :class="agree ? 'btn-primary' : 'btn-disabled'" @click="makeInterrupt">Transfer confirmed</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import {mapGetters} from 'vuex'
import {currencySign, timeToString} from '@/common/helpers'
import {ACTIVITIES_STATIC_BASE, STAKING_FIXED, STAKING_FLEXIBLE} from '@/common/config'
import {
    YUAN_ACTIVITY_INVEST,
    YUAN_ACTIVITY_INVESTMENT_REQUEST,
    YUAN_STAKE_CLOSE, YUAN_STAKE_INTERRUPT,
    YUAN_STAKE_REQUEST
} from '@/store/actions.type'

export default {
    name: "Staking",
    mounted() {
        setInterval(() => {
            this.time = Math.floor(Date.now()/1000)
        }, 5000)

        for(let n in STAKING_FIXED) {
            Vue.set(this.periodChosen, n, 0)
        }
    },
    data() {
        return {
            time: Math.floor(Date.now()/1000),
            stakingFlexible: STAKING_FLEXIBLE,
            stakingFixed: STAKING_FIXED,
            periodChosen: {},
            fixedModal: false,
            flexibleModal: false,
            fixedModalId: 'BTC_fixed',
            flexibleModalId: 'BTC_flexible',
            fixedModalPeriod: 0,
            fixedInterruptModal: false,
            fixedInterruptModalId: 0,
            fixedInterruptModalPlanId: 0,
            fixedInterruptModalNumbers: {
                balance: 0,
                earned: 0,
                penalty: 0
            },
            activitiesModal: false,
            activitiesModalId: 0,
            amount: '',
            agree: false,
            stakingCurrency: 'BTC',
            activitiesStaticBase: ACTIVITIES_STATIC_BASE
        }
    },
    methods: {
        showFixedModal(id, period) {
            this.fixedModal = true
            this.fixedModalId = id
            this.fixedModalPeriod = period

            this.amount = ''
            this.agree = false
            this.stakingCurrency = this.stakingFixed[id].currency
        },
        showFlexibleModal(id) {
            this.flexibleModal = true
            this.flexibleModalId = id

            this.amount = ''
            this.agree = false
            this.stakingCurrency = this.stakingFlexible[id].currency
        },
        showActivitiesModal(id) {
            this.activitiesModal = true
            this.activitiesModalId = id

            this.amount = ''
            this.agree = false
            this.stakingCurrency = 'YNP'
        },
        showFixedInterruptModal(id, planId) {
            this.fixedInterruptModal = true
            this.fixedInterruptModalId = id
            this.fixedInterruptModalPlanId = planId
            this.agree = false

            this.fixedInterruptModalNumbers.balance = Number(this.stakingAccounts.all[id].balance.toFixed(this.stakingAccounts.all[id].precision))

            this.fixedInterruptModalNumbers.earned = Number((this.stakingAccounts.all[id].balance * this.stakingFixed[planId][this.stakingAccounts.all[id].staking_currency === 'YNP' ? 'interestsYNP' : 'interests'][this.stakingAccounts.all[id].group.split('.')[3]] * (this.time - this.stakingAccounts.all[id].created) / (365 * 86400 * 100)).toFixed(this.stakingAccounts.all[id].precision))

            this.fixedInterruptModalNumbers.penalty = Number((this.fixedInterruptModalNumbers.earned * 0.3).toFixed(this.stakingAccounts.all[id].precision))
        },
        makeStaking(type) {
            if (type === 'fixed') {
                this.$store.dispatch(YUAN_STAKE_REQUEST, {
                    type: 'fixed',
                    account_id: this.wallets[STAKING_FIXED[this.fixedModalId].currency].id,
                    plan: this.fixedModalId,
                    amount: Math.floor(this.amount) * STAKING_FIXED[this.fixedModalId].lot,
                    staking_currency: this.stakingCurrency,
                    period: this.fixedModalPeriod
                })

                this.fixedModal = false
            } else if (type === 'flexible') {
                this.$store.dispatch(YUAN_STAKE_REQUEST, {
                    type: 'flexible',
                    account_id: this.wallets[STAKING_FLEXIBLE[this.flexibleModalId].currency].id,
                    plan: this.flexibleModalId,
                    amount: this.amount,
                    staking_currency: this.stakingCurrency
                })

                this.flexibleModal = false
            }
        },
        makeInterrupt() {
            this.fixedInterruptModal = false
            this.$store.dispatch(YUAN_STAKE_INTERRUPT, this.fixedInterruptModalId)
        },
        investActivity() {
            this.$store.dispatch(YUAN_ACTIVITY_INVEST, {
                activity_id: this.activitiesModalId,
                account_id: this.wallets['YNP'].id,
                amount: this.amount
            })

            this.activitiesModal = false
        },
        closeStaking(id) {
            this.$store.dispatch(YUAN_STAKE_CLOSE, id)
        },
        requestOffer(id) {
            this.$store.dispatch(YUAN_ACTIVITY_INVESTMENT_REQUEST, {
                activity_id: id
            })
        },
        currencySign,
        timeToString
    },
    computed: {
        ...mapGetters(['symbols', 'accounts', 'activities', 'activityInvestments']),
        wallets() {
            let ret = {
                BTC: {
                    balance: 0,
                    precision: 4
                },
                ETH: {
                    balance: 0,
                    precision: 4
                },
                DOGE: {
                    balance: 0,
                    precision: 2
                },
                USDT: {
                    balance: 0,
                    precision: 2
                },
                TRX: {
                    balance: 0,
                    precision: 2
                },
                YNP: {
                    balance: 0,
                    precision: 4
                }
            }

            for(let n in this.accounts) {
                if (this.accounts[n].alias === 'BTC Wallet') {
                    ret.BTC = this.accounts[n]
                }
                if (this.accounts[n].alias === 'ETH Wallet') {
                    ret.ETH = this.accounts[n]
                }
                if (this.accounts[n].alias === 'DOGE Wallet') {
                    ret.DOGE = this.accounts[n]
                }
                if (this.accounts[n].alias === 'USDT Wallet') {
                    ret.USDT = this.accounts[n]
                }
                if (this.accounts[n].alias === 'TRX Wallet') {
                    ret.TRX = this.accounts[n]
                }
                if (this.accounts[n].alias === 'YNP Wallet') {
                    ret.YNP = this.accounts[n]
                }
            }

            return ret
        },
        stakingAccounts() {
            let accs = {
                'fixed': {},
                'flexible': {},
                'all': {}
            }

            for(let n in STAKING_FIXED) {
                accs.fixed[n] = []
            }

            for(let n in STAKING_FLEXIBLE) {
                accs.flexible[n] = []
            }

            for(let n in this.accounts) {
                let acc = this.accounts[n],
                    planData = acc.group.split('.')

                if (planData.length < 3) {
                    continue
                }

                let plan = planData[1] === 'fixed' ? STAKING_FIXED[planData[2]] : STAKING_FLEXIBLE[planData[2]],
                    interest = (planData[1] === 'fixed' ? (plan[acc.staking_currency === 'YNP' ? 'interestsYNP' : 'interests'][planData[3]]) : plan[acc.staking_currency === 'YNP' ? 'interestYNP' : 'interest']) / 100,
                    days = (this.time - acc.created) / 86400

                acc.interest = interest * 100

                if (planData[1] === 'fixed') {
                    days = Math.min(plan.periods[planData[3]], days)
                    acc.days_left = Math.ceil(plan.periods[planData[3]] - days)
                }

                acc.earned = days * interest * acc.balance / 365

                if (acc.currency !== acc.staking_currency) {
                    acc.earned *= this.symbols[acc.currency+'USD'].quote.bid / this.symbols[acc.staking_currency+'USD'].quote.bid
                }

                acc.earned = Math.max(0, acc.earned).toFixed(acc.precision)

                accs[planData[1]][planData[2]].push(acc)

                accs['all'][acc.id] = acc
            }

            return accs
        },
        soldOutList() {
            let c = []

            for(let n in this.activities) {
                c.push(this.activities[n])
            }

            return c.sort((a, b) => b.pct_annual - a.pct_annual).map(a => a.id).slice(8)
        }
    }
}
</script>

<style scoped>
    .staking {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        flex: 1 1 auto;
    }

    .align-flex-end {
        margin: 20px 0;
    }

    .about {
        font-size: 1em;
        margin-left: 8px;
        color: var(--font-secondary)
    }

    h4 {
        font-weight: 500;
        margin-bottom: 0;
    }

    table {
        table-layout: fixed;
        width: 100%;
    }

    .staking-row {
        border-bottom: 1px solid var(--border);
    }

    th, td {
        padding: 8px;
    }

    td {
        font-size: 1.4em;
        text-align: center;
        cursor: pointer;
    }

    td:first-child {
        text-align: initial;
    }

    .name img {
        width: 32px;
        height: 32px;
        margin: 0 10px;
    }

    .name span {
        font-weight: 400;
    }

    table .btn {
        font-size: 0.8em;
        margin-right: 10px;
    }

    div.pad {
        width: 100%;
        margin-bottom: 40px;
    }

    .periods {
        display: flex;
        align-items: center;
    }

    .periods .item {
        border: 1px solid var(--brand);
        border-radius: 5px;
        cursor: pointer;
        margin-right: 10px;
        color: var(--brand);
        display: flex;
        align-items: center;
        justify-content: center;
        height: 20px;
        width: 50px;
        font-size: 0.8em;
    }

    .periods .item.active, .periods .item:hover {
        background: var(--brand);
        color: var(--white)
    }

    .modal {
        padding: 15px 20px;
    }

    .modal-fixed {
        width: 700px;
    }

    .modal-interrupt {
        width: 350px;
    }

    .title {
        font-size: 1.3em;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: var(--font-blue);
        margin-bottom: 20px;
    }

    .title i {
        font-size: 1.3em;
        cursor: pointer;
    }

    label {
        display: flex;
        justify-content: space-between;
    }

    .grey {
        color: var(--font-secondary);
        white-space: nowrap;
    }

    .val {
        font-weight: 500;
        white-space: nowrap;
        padding-left: 5px;
    }

    .input {
        border: 1px solid rgba(0,0,0,0.2);
        border-radius: 5px;
        padding: 5px 10px 5px 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 0 20px;
        cursor: pointer;
        position: relative;
    }

    .input img {
        width: 20px;
        height: 20px;
        margin-right: 5px;
    }

    .input input {
        border: none;
        flex: 1 1 auto;
    }

    .input .max {
        font-size: 1.3em;
        color: var(--brand);
        cursor: pointer;
        margin-right: 10px;
    }

    .info-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 1.2em;
        margin-bottom: 15px;
    }

    .font-12 {
        font-size: 1.2em;
    }

    .font-15 {
        font-size: 1.5em;
    }

    .modal .periods .item {
        font-size: 1.1em;
    }

    .stretch .col {
        flex: 1 1 40%;
        margin-right: 15px;
    }

    .stretch .col:last-child {
        margin-right: 0;
    }

    .stretch .info-row {
        margin-bottom: 24px;
    }

    td.name {
        font-size: 1.4em;
        font-weight: 400;
    }

    td.promo {
        font-size: 1.6em;
        color: var(--brand);
        font-weight: 500;
    }

    .positions {
        width: 70%;
        margin: 10px 0 30px auto;
    }

    .positions {
        font-size: 0.8em;
    }

    .positions th {
        background: var(--background-dark);
    }

    .positions td, .positions th {
        padding: 4px;
    }

    .activities-container {
        display: flex;
        flex-wrap: wrap;
    }

    .activity {
        display: flex;
        flex: 1 1 auto;
        max-width: 400px;
        min-width: 300px;
        flex-direction: column;
        align-items: center;
        border: 1px solid var(--border);
        border-radius: 10px;
        padding: 20px;
        margin: 20px;
        position: relative;
    }

    .sold-out {
        position: absolute;
        top: 10px;
        left: 0;
        right: 0;
        text-align: center;
        font-size: 1.5em;
        color: var(--red);
        font-weight: 600;
    }

    .sold {
        background: rgba(221, 73, 49, 0.05);
    }

    .activity .row {
        flex-wrap: nowrap;
    }

    .activity .col {
        align-items: flex-start;
    }

    .activity .col.align-flex-end {
        align-items: flex-end;
    }

    .activity-title {
        font-size: 1.7em;
        font-weight: 500;
    }

    .activity-description {
        color: var(--font-secondary);
        margin-top: 5px;
    }

    .activity-target {
        font-size: 1.4em;
        font-weight: 500;
        color: var(--green);
        white-space: nowrap;
        margin-left: 20px;
    }

    .activity img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin: 0 10px;
    }

    .activity .annual-pct {
        font-size: 1.5em;
        color: var(--brand);
        font-weight: 500;
    }

    .activity .grey {
        font-weight: 300;
    }

    .between {
        width: 100%;
    }

    .pad-top {
        margin-top: 10px;
    }

    @media (max-width: 1023px) {
        td {
            white-space: nowrap;
        }

        .activity {
            margin: 0 0 20px 0;
            padding: 40px 20px 20px;
        }

        .activity .row.between {
            flex-direction: column;
        }

        .activity .btn, .activity .brand {
            margin-top: 20px;
        }
    }
</style>