<template>
    <div id="app">
        <div class="blur-container" :class="{blur: blur}">
            <Header/>
            <div class="main">
                <SideMenu class="main-menu" />
                <Dashboard v-if="page === 'dashboard'"/>
                <Profile v-if="page === 'personal'"/>
                <Verification v-if="page === 'kyc'"/>
                <Withdrawal v-if="page === 'withdrawal'"/>
                <Deposit v-if="page === 'deposit'"/>
                <Settings v-if="page === 'settings'"/>
                <StakingNew v-if="page === 'staking'"/>
                <Referral v-if="page === 'referral'"/>
            </div>
            <Footer/>
        </div>
        <Register v-if="page === 'register'"/>
        <Login v-if="page === 'login'"/>
        <Welcome />
        <ForgotPassword v-if="page === 'forgot-password'"/>
        <NewPassword v-if="page === 'new-password'"/>
        <ModalExchange />
<!--        <ModalStake />-->
        <Notifications/>
        <Chat/>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import Register from '@/components/Register'
import Login from '@/components/Login'
import {
    APP_INIT,
    DEPOSITS_GET,
    DOCUMENTS_GET,
    OTP_CREATE,
    TRANSACTIONS_GET,
    WITHDRAWALS_GET, YUAN_REFERRAL_GET
} from '@/store/actions.type'
import Notifications from '@/components/Notifications'
import SideMenu from '@/components/SideMenu'
import Dashboard from '@/components/Dashboard'
import Profile from '@/components/Profile'
import Verification from '@/components/Verification'
import Withdrawal from '@/components/Withdrawal'
import Deposit from '@/components/Deposit'
import ModalExchange from '@/components/ModalExchange'
import Settings from '@/components/Settings'
import StakingNew from '@/components/StakingNew'
// import ModalStake from '@/components/ModalStake'
import Chat from '@/components/Chat'
import Referral from '@/components/Referral'
import ForgotPassword from '@/components/ForgotPassword'
import NewPassword from '@/components/NewPassword'
import Welcome from '@/components/Welcome'

export default {
    name: 'App',
    components: {
        Welcome,
        NewPassword,
        ForgotPassword,
        Referral,
        Chat,
        // ModalStake,
        StakingNew,
        Settings,
        ModalExchange,
        Deposit,
        Withdrawal,
        Verification,
        Profile,
        Dashboard,
        SideMenu,
        Notifications,
        Login,
        Register,
        Footer,
        Header
    },
    mounted() {
        this.$store.dispatch(APP_INIT)
    },
    computed: {
        ...mapGetters(['page', 'blur', 'user'])
    },
    watch: {
        page(val) {
            switch (val) {
                case 'deposit':
                    this.$store.dispatch(DEPOSITS_GET)
                    this.$store.dispatch(TRANSACTIONS_GET)
                    break
                case 'withdrawal':
                    this.$store.dispatch(WITHDRAWALS_GET)
                    break
                case 'kyc':
                    this.$store.dispatch(DOCUMENTS_GET)
                    break
                case 'referral':
                    this.$store.dispatch(YUAN_REFERRAL_GET)
                    break
                case 'personal':
                    if (!this.user.otp) {
                        this.$store.dispatch(OTP_CREATE)
                    }
                    break
            }
        }
    }
}
</script>

<style>
:root {
    --background: #ffffff;
    --background-dark: #f6882f;
    --font: #000000;
    --font-secondary: #888888;
    --font-blue: #f6882f;
    --brand: #ff5857;
    --green: #31cf5a;
    --red: #dd4931;
    --white: #ffffff;
    --border: #f0f2f7;
}

html, body, #app {
    position: fixed;
    height: 100%;
    width: 100%;
    min-width: 100%;
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 12px;
    font-weight: 400;
    background: var(--background);
    color: var(--font);
}

*, *:before, *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

*:focus {
    outline: none !important;
    caret-color: var(--brand)
}

::-webkit-scrollbar {
    width: 1px;
    height: 1px;
}

::-webkit-scrollbar-track {
    background: var(--background-dark);
}

::-webkit-scrollbar-thumb, ::-webkit-scrollbar-thumb:hover {
    background: var(--brand);
}

::-webkit-scrollbar-corner {
    background: rgba(0,0,0,0);
}

.noscroll::-webkit-scrollbar {
    width: 0;
    height: 0;
}

#app, .blur-container {
    display: flex;
    flex-direction: column;
    position: relative;
    overflow-y: scroll;
    flex: 1 1 auto;
}

.blur {
    -webkit-filter: blur(8px);
    -moz-filter: blur(8px);
    -o-filter: blur(8px);
    -ms-filter: blur(8px);
    filter: blur(8px);
}

.hide {
    display: none !important;
}

.green {
    color: var(--green) !important;
}

.red {
    color: var(--red) !important;
}

.brand {
    color: var(--brand) !important;
}

.row {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.col {
    display: flex;
    flex-direction: column;
}

.modal-wrapper {
    position: fixed;
    z-index: 1080;
    left: 0;
    top: 0;
    background-color: rgba(0,0,0,0.2);
    width: 100%;
    height: 100vh;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal {
    width: 350px;
    display: flex;
    background: var(--background);
    border-radius: 8px;
    -webkit-box-shadow: 0 0 10px 0 rgba(0,0,0,0.5);
    -moz-box-shadow: 0 0 10px 0 rgba(0,0,0,0.5);
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.5);
    flex-direction: column;
    align-items: stretch;
}

.scroll {
    overflow-y: scroll;
    position: relative;
}

.between {
    display: flex;
    justify-content: space-between;
}

.around {
    justify-content: space-around;
}

.stretch {
    align-items: stretch;
}

.flex-end {
    justify-content: flex-end;
}

.align-flex-end {
    align-items: flex-end;
}

h4 {
    font-size: 1.7em;
    font-weight: 300;
    margin-bottom: 20px;
}

a {
    color: var(--brand);
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

input {
    color: var(--font);
    -webkit-touch-callout: initial !important;
    -webkit-user-select: initial !important;
    -moz-user-select: initial !important;
    -ms-user-select: initial !important;
    user-select: initial !important;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image:
        linear-gradient(45deg, transparent 50%, var(--font) 50%),
        linear-gradient(135deg, var(--font) 50%, transparent 50%);
    background-position:
        calc(100% - 0.75rem - 5px) 1em,
        calc(100% - 0.75rem) 1em;
    background-size:
        5px 5px,
        5px 5px,
        1px 1.5em;
    background-repeat: no-repeat;
}

button {
    border: 1px solid var(--font);
    background: var(--background);
    border-radius: 3px;
    color: var(--font);
    cursor: pointer;
    padding: 8px 12px;
}

label {
    font-size: 1.1em;
    font-weight: 400;
    margin-bottom: 0.5em;
}

input, select, textarea {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1.1em;
    font-weight: 400;
    line-height: 1.5;
    color: var(--font);
    background-color: var(--background);
    background-clip: padding-box;
    border: 1px solid var(--background-dark);
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image:
        linear-gradient(45deg, transparent 50%, var(--font) 50%),
        linear-gradient(135deg, var(--font) 50%, transparent 50%);
    background-position:
        calc(100% - 0.75rem - 5px) 1em,
        calc(100% - 0.75rem) 1em;
    background-size:
        5px 5px,
        5px 5px,
        1px 1.5em;
    background-repeat: no-repeat;
}

th {
    text-align: center;
    background-color: var(--brand);
    color: var(--background);
    font-weight: 500;
}

.form-control {
    background-color: var(--background);
    color: var(--font);
    border: 0;
    padding: 8px 12px;
    cursor: pointer;
}

.form-control::placeholder {
    color: var(--font)
}

.pointer {
    cursor: pointer;
}

.material-icons {
    font-size: 1em;
}

.main {
    display: flex;
    align-items: stretch;
    flex: 1 1 auto;
    background-size: contain;
}

.content {
    padding: 20px;
    flex: 1 1 auto;
    display: flex;
    align-items: stretch;
}

.form-row {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
}

.btn {
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 0.3em 1em;
    border: 1px solid transparent;
    font-size: 1.3em;
    line-height: 1.5;
    border-radius: 0.25em;
    transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
}

.btn-primary {
    color: var(--background);
    background-color: var(--background-dark);
    border-color: var(--background-dark);
}

.btn-primary:hover {
    background-color: var(--brand);
}

.btn-brand {
    color: var(--background);
    background-color: var(--brand);
    border-color: var(--brand);
}

.btn-brand:hover {
    background-color: var(--brand);
}

.btn-secondary {
    background-color: transparent;
    color: var(--font);
    border-color: var(--font-secondary);
}

.btn-secondary:hover {
    background-color: var(--font-secondary);
}

.btn-transparent {
    background-color: transparent;
    border-color: var(--brand);
    color: var(--font)
}

.btn-transparent:hover {
    background-color: var(--brand);
    color: var(--font)
}

.btn-disabled {
    background: rgba(0,0,0,0.1);
    color: var(--font-secondary);
    cursor: initial;
}

.btn-green {
    background-color: var(--green);
    border-color: var(--green);
    color: var(--font)
}

.btn-green:hover {
    background-color: transparent;
    color: var(--font)
}

.btn-blue {
    background-color: var(--font-blue);
    border-color: var(--font-blue);
    color: var(--font)
}

.btn-blue:hover {
    background-color: transparent;
    color: var(--font)
}

.two-cols {
    display: flex;
    margin: 30px;
    flex: 1 1 auto;
}

.two-cols .onecol {
    flex: 1 1 50%;
    padding: 15px 20px;
    border: 1px solid rgba(0,0,0,0.1);
    border-radius: 10px;
}

.onecol:first-child {
    margin-right: 20px;
}

.onecol:last-child {
    margin-right: 0;
    padding-left: 20px;
}

.info-color {
    color: var(--font-secondary);
    font-weight: 400;
}

.text-muted {
    font-weight: 400;
    color: #6c757d !important;
    margin: 5px 5px 0;
    display: block;
}

.subaction {
    border-bottom: 1px dotted;
    cursor: pointer;
}

.material-icons.large {
    font-size: 4em;
    width: 100%;
    margin: 0 0 10px;
    color: var(--brand)
}

.extra-text {
    color: var(--font);
    font-weight: 400;
    line-height: 2;
}

.toggler {
    display: flex;
    align-items: stretch;
    width: 100%;
    height: 40px;
    background: rgba(0,0,0,0.05);
    border-radius: 10px;
    margin: 10px 0;
}

.toggler .item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 1 auto;
    border: 1px solid transparent;
    cursor: pointer;
}

.toggler .item.active {
    border-radius: 10px;
    border-color: var(--background-dark);
    background: var(--background);
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
}

.terms-row {
    flex-direction: row !important;
    align-items: center;
}

.terms {
    margin-left: 10px;
    font-weight: 400;
}

.check {
    width: 20px !important;
}

.table-container {
    width: 100%;
}

.mobile {
    display: none !important;
}

@media (max-width: 1023px) {
    .nomobile {
        display: none !important;
    }

    .mobile {
        display: flex !important;
    }

    .two-cols {
        flex-direction: column;
        margin: 20px 10px;
        width: calc(100vw - 20px);
    }

    .onecol:first-child {
        margin-right: 0;
        margin-bottom: 20px;
    }

    table {
        width: auto !important;
        min-width: 100%;
    }

    .table-container {
        overflow-x: scroll;
    }

    .chat {
        right: 0 !important;
        width: 100% !important;
    }

    .main-menu {
        display: none !important;
    }
}
</style>
