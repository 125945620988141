<template>
    <div class="symbols-select">
        <div class="item"
             v-for="(symbol, i) in selectSymbols"
             :key="i"
             :class="{active: symbol.symbol === symbolActive}"
             @click.stop="makeActive(symbol.symbol)"
        >
            <img :src="symbol.icon" />
            <div class="name">{{ symbols[symbol.symbol] ? symbols[symbol.symbol].alias : '' }}</div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {CHART_SYMBOLS} from '@/common/config'

export default {
    name: 'SymbolsSelect',
    props: ['initSymbol'],
    data() {
        return {
            selectSymbols: CHART_SYMBOLS,
            symbolActive: 'YNPUSD'
        }
    },
    methods: {
        makeActive(sym) {
            this.symbolActive = sym

            this.$emit('active', sym)
        }
    },
    computed: {
        ...mapGetters(['symbols'])
    },
    watch: {
        initSymbol(val) {
            this.symbolActive = val
        }
    }
}
</script>

<style scoped>
    .symbols-select {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        border: 1px solid var(--border);
        padding: 5px 0;
        border-radius: 4px;
        position: absolute;
        z-index: 3;
        background: var(--background);
    }

    .item {
        display: flex;
        align-items: center;
        padding: 8px 20px;
        border-bottom: 1px solid var(--border);
    }

    .item:last-child {
        border-bottom: none;
    }

    .item.active, .item:hover {
        background: rgba(13,52,120,0.2);
    }

    .name {
        font-size: 1.2em;
        font-weight: 400;
    }

    img {
        height: 20px;
        width: 20px;
        margin-right: 10px;
    }
</style>