<template>
    <div class="dashboard">
        <Balances class="dashboard-col" />
        <Chart class="dashboard-col" />
    </div>
</template>

<script>
import Balances from '@/components/Balances'
import Chart from '@/components/Chart'
export default {
    name: "Dashboard",
    components: {Chart, Balances}
}
</script>

<style scoped>
    .dashboard {
        display: flex;
        align-items: stretch;
        flex: 1 1 auto
    }

    @media (max-width: 1023px) {
        .dashboard {
            flex-direction: column;
        }

        .dashboard-col {
            width: calc(100vw - 20px);
        }
    }
</style>