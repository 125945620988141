<template>
    <div class="referral">
        <div class="two-cols">
            <div class="onecol">
                <div class="row align-flex-end">
                    <h4>Invitation Codes</h4>
                    <span class="about">Invite investors and receive 5% from their staking profit</span>
                </div>
                <table>
                    <thead>
                    <tr>
                        <th>Invitation Code</th>
                        <th>Investor</th>
                    </tr>
                    </thead>
                </table>
                <div class="staking-row"
                     v-for="code in referralCodes"
                     :key="code.code"
                >
                    <table>
                        <tbody>
                        <tr>
                            <td class="code">
                                <div class="row">
                                    <input :id="code.code" :value="code.code" readonly="readonly" />
                                    <button class="btn btn-primary" @click="copyCode(code.code)">Copy</button>
                                    <button class="btn btn-primary" @click="copyLink(code.code)">Copy Invitation Link</button>
                                </div>
                            </td>
                            <td>
                                {{ code.email }}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <table
                        :class="{hide: code.accounts.length === 0}"
                        class="positions"
                    >
                        <thead>
                        <tr>
                            <th>Staking ID</th>
                            <th>Type</th>
                            <th>Size</th>
                            <th>Profit</th>
                            <th>Money Release In</th>
                            <th>Interest</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr
                            v-for="account in code.accounts"
                            :key="account.id"
                        >
                            <td class="name">
                                Staking #{{ account.hash_id }}
                            </td>
                            <td class="name">
                                {{ interests[account.id].type === 'fixed' ? 'Fixed' : 'Flexible' }}
                            </td>
                            <td>
                                {{ account.currency }} {{ account.balance.toFixed(account.precision) }}
                            </td>
                            <td>
                                {{ account.staking_currency }} {{ account.earned }}
                            </td>
                            <td>
                                {{ interests[account.id].type === 'fixed' ? interests[account.id].days_left+' days' : 'n/a' }}
                            </td>
                            <td :class="{promo: account.staking_currency === 'YNP'}">
                                {{ interests[account.id].interest.toFixed(2) }}%
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'
    import {REGISTER_LINK, STAKING_FIXED, STAKING_FLEXIBLE} from '@/common/config'

    export default {
        name: 'Referral',
        mounted() {
            setInterval(() => {
                this.time = Math.floor(Date.now() / 1000)
            }, 5000)
        },
        data() {
            return {
                time:  Math.floor(Date.now() / 1000)
            }
        },
        methods: {
            copyCode(code) {
                document.getElementById(code).select();
                document.execCommand('copy')
            },
            copyLink(code) {
                let input = document.createElement('input')
                input.value = REGISTER_LINK+'/?invitation='+code

                document.body.appendChild(input)
                input.select()
                document.execCommand('copy')

                document.body.removeChild(input)
            }
        },
        computed: {
            ...mapGetters(['referralCodes']),
            interests() {
                let interests = {}

                for(let n in this.referralCodes) {
                    for(let m in this.referralCodes[n].accounts) {
                        let acc = this.referralCodes[n].accounts[m],
                            planData = acc.group.split('.')

                        if (planData.length < 3) {
                            continue
                        }

                        let plan = planData[1] === 'fixed' ? STAKING_FIXED[planData[2]] : STAKING_FLEXIBLE[planData[2]],
                            interest = (planData[1] === 'fixed' ? (plan[acc.staking_currency === 'YNP' ? 'interestsYNP' : 'interests'][planData[3]]) : plan[acc.staking_currency === 'YNP' ? 'interestYNP' : 'interest']) / 100,
                            days = (this.time - acc.created) / 86400

                        interests[acc.id] = {
                            type: planData[1] === 'fixed' ? 'fixed' : 'flexible',
                            interest: interest * 100
                        }

                        if (planData[1] === 'fixed') {
                            days = Math.min(plan.periods[planData[3]], days)
                            interests[acc.id].days_left = Math.ceil(plan.periods[planData[3]] - days)
                        } else {
                            interests[acc.id].days_left = 0
                        }
                    }
                }

                return interests
            }
        }
    }
</script>

<style scoped>
    .referral {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        flex: 1 1 auto;
    }

    .align-flex-end {
        margin: 20px 0;
    }

    .about {
        font-size: 1em;
        margin-left: 8px;
        color: var(--font-secondary)
    }

    h4 {
        font-weight: 500;
        margin-bottom: 0;
    }

    table {
        table-layout: fixed;
        width: 100%;
    }

    .staking-row {
        border-bottom: 1px solid var(--border);
    }

    th, td {
        padding: 8px;
    }

    td {
        font-size: 1.4em;
        text-align: center;
        cursor: pointer;
    }

    td:first-child {
        text-align: initial;
    }

    .positions {
        width: 70%;
        margin: 10px 0 30px auto;
    }

    .positions {
        font-size: 0.8em;
    }

    .positions th {
        background: var(--background-dark);
    }

    .positions td, .positions th {
        padding: 4px;
    }

    .code {
        font-size: 1em;
    }

    .code .row {
        justify-content: center;
        font-size: 1em;
    }

    .code input {
        border: none;
        width: 270px;
    }

    .code .btn {
        padding: 4px 10px;
        font-size: 1em;
        margin-left: 10px;
    }
</style>