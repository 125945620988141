<template>
    <div class="side-menu">
        <div class="balance">
            <div>Total Balance</div>
            <div>{{currencySign(displayCurrency)}}{{ total.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</div>
        </div>
        <div class="menu">
            <div class="item" :class="{active: page === 'dashboard'}" @click="changePage('dashboard')">
                <i class="material-icons">home</i>
                <div>Dashboard</div>
            </div>
            <div class="item" :class="{active: page === 'deposit'}" @click="changePage('deposit')">
                <i class="material-icons">account_balance_wallet</i>
                <div>Financial</div>
            </div>
            <div class="item" :class="{active: page === 'staking'}" @click="changePage('staking')">
                <i class="material-icons">group_work</i>
                <div>Staking</div>
            </div>
            <div class="item" :class="{active: page === 'personal'}" @click="changePage('personal')">
                <i class="material-icons">account_box</i>
                <div>Personal Details</div>
            </div>
<!--            <div class="item" :class="{active: page === 'kyc'}" @click="changePage('kyc')">-->
<!--                <i class="material-icons">policy</i>-->
<!--                <div>KYC/AML</div>-->
<!--            </div>-->
            <div class="item" :class="{active: page === 'withdrawal'}" @click="changePage('withdrawal')">
                <i class="material-icons">account_balance</i>
                <div>Withdrawal</div>
            </div>
            <div class="item" :class="{active: page === 'referral'}" @click="changePage('referral')">
                <i class="material-icons">people_alt</i>
                <div>Referral</div>
            </div>
            <div class="item" :class="{active: page === 'settings'}" @click="changePage('settings')">
                <i class="material-icons">settings</i>
                <div>Settings</div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {PAGE} from '@/store/mutations.type'
import {currencySign} from '@/common/helpers'

export default {
    name: "SideMenu",
    methods: {
        changePage(page) {
            this.$emit('pageChange')
            this.$store.commit(PAGE, page)
        },
        currencySign
    },
    computed: {
        ...mapGetters(['page', 'accounts', 'symbols', 'displayCurrency']),
        total() {
            let total = 0

            for(let n in this.accounts) {
                let a = this.accounts[n]

                if (a.product !== 'savings' || !a.alias) {
                    continue
                }

                a.balanceUSD = this.symbols[a.currency+'USD'] ? a.balance * this.symbols[a.currency+'USD'].quote.bid : 0

                total += a.balanceUSD
            }

            if (this.displayCurrency !== 'USD') {
                if (this.symbols[this.displayCurrency+'USD']) {
                    total /= this.symbols[this.displayCurrency+'USD'].quote.bid
                } else if (this.symbols['USD'+this.displayCurrency]) {
                    total *= this.symbols['USD'+this.displayCurrency].quote.bid
                }
            }

            return total
        }
    }
}
</script>

<style scoped>
    .side-menu {
        flex: 0 0 220px;
        display: flex;
        flex-direction: column;
        padding: 15px;
    }

    .balance {
        display: flex;
        flex-direction: column;
        padding: 10px 10px 0;
    }

    .balance div:first-child {
        font-size: 1em;
        margin-bottom: 5px;
        font-weight: 400;
    }

    .balance div:last-child {
        font-size: 2em;
        font-weight: 400;
    }

    .menu {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
    }

    .menu .item {
        margin-bottom: 10px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 8px 10px;
        font-size: 1.3em;
        font-weight: 400;
        height: 50px;
        overflow: hidden;
        background: rgba(0,0,0,0.02);
    }

    .menu .item:hover, .menu .item.active {
        color: var(--background);
        background: var(--background-dark);
        -webkit-box-shadow: inset 0px 0px 100px 0px rgba(255, 88, 88, 1);
        -moz-box-shadow: inset 0px 0px 100px 0px rgba(255,88,88,1);
        box-shadow: inset 0px 0px 100px 0px rgba(255,88,88,1);
    }

    i {
        color: var(--background-dark);
        font-size: 1.7em;
        margin-right: 20px;
        width: 24px;
    }

    .menu .item:hover i, .menu .item.active i {
        color: var(--white);
    }

    @media (max-width: 1023px) {
        .side-menu {
            position: absolute;
            left: 0;
            right: 0;
            top: 37px;
            background: var(--background-dark);
            z-index: 5;
        }

        i {
            color: var(--white);
        }

        .menu .item {
            background: rgba(255, 255, 255, 0.2);
            color: var(--white)
        }

        .balance {
            color: var(--white)
        }
    }
</style>