<template>
    <div class="deposit">
        <div class="two-cols">
            <div class="onecol">
                <h4 class="between">Deposit Funds <i class="material-icons hover-activate">help_outline</i>
                    <span class="hover-text">{{ tokens.HelpDeposit2 }}</span>
                </h4>
                <div class="form-row">
                    <select v-model="currency">
                        <option value="BTC">Bitcoin</option>
                        <option value="YNP-TRC20">YuanPay (TRC20)</option>
                        <option value="ETH">Ethereum</option>
                        <option value="DOGE">Dogecoin</option>
                        <option value="USDT-TRC20">Tether USD (TRC20)</option>
                        <option value="TRX">Tron</option>
                    </select>
                </div>
                <div class="form-row form-actions" style="justify-content: flex-start !important;">
                    <button type="button" class="btn btn-primary" v-on:click="makeDeposit">{{ tokens.MakeDeposit }}</button>
                </div>
                <div v-bind:class="{hide: depositAddress === ''}" class="form-row">
                    <label>{{ tokens.Address }}</label>
                    <input type="text" v-bind:value="depositAddress" disabled="disabled">
                    <span class="text-muted">{{ tokens.HelpDeposit1.split('%currency%').join(currency) }}</span>
                </div>
                <div class="form-row" v-bind:class="{hide: depositAddress === ''}" style="display: flex; align-items: center; justify-content: center">
                    <img v-bind:src="depositAddress ? 'https://chart.googleapis.com/chart?chs=200x200&cht=qr&chl='+depositAddress : ''" />
                </div>
            </div>
            <div class="onecol extra-text">
                <h4>Exchange Cryptocurrency</h4>
                <div class="exchange">
                    <div>
                        <div class="available">
                            <span>Available</span>
                            <span>{{ currencySign(active) }}{{ Number(wallets[active] ? (Math.floor(wallets[active].balance * Math.pow(10, wallets[active].precision) + Number.EPSILON) / Math.pow(10, wallets[active].precision) + Number.EPSILON).toFixed(wallets[active].precision) : 0) }}</span>
                        </div>
                        <div class="input">
                            <img :src="'/images/crypto-icons/'+(active === 'YNP' ? 'CNY' : active).toLowerCase()+'@2x.png'" @click="selectActive = true" />
                            <input v-model="amount" @keyup="updateAmount" />
                            <div class="max" @click="setMax(active)">MAX</div>
                            <div class="currency">{{ active }}</div>
                            <CurrenciesSelect init-symbol="BTC" class="select-sym" @active="changeSymbol" :class="{hide: !selectActive}"/>
                        </div>
                    </div>
                    <div class="change-ico" @click="swapActives">
                        <i class="material-icons">import_export</i>
                    </div>
                    <div>
                        <div class="row between">
                            <div class="available">
                                <span>Available</span>
                                <span>{{ currencySign(activeTo) }}{{ Number(wallets[activeTo] ? Number(wallets[activeTo].balance.toFixed(wallets[activeTo] ? wallets[activeTo].precision : 0)) : 0) }}</span>
                            </div>
                            <div class="available" :class="{hide: active !== 'YNP'}">
                                <span>Limit</span>
                                <span>{{ currencySign(activeTo) }}{{ (exchangeLimits[activeTo] ? Number(exchangeLimits[activeTo])  : 0).toFixed(wallets[activeTo] ? wallets[activeTo].precision : 0) }}</span>
                            </div>
                        </div>
                        <div class="input">
                            <img :src="'/images/crypto-icons/'+(activeTo === 'YNP' ? 'CNY' : activeTo).toLowerCase()+'@2x.png'" @click="selectActiveTo = true" />
                            <input v-model="amountTo" @keyup="updateAmountTo" />
                            <div class="currency">{{ activeTo }}</div>
                            <CurrenciesSelect init-symbol="BTC" class="select-sym" @active="changeSymbolTo" :class="{hide: !selectActiveTo}"/>
                        </div>
                    </div>
                </div>
                <div class="row between fees">
                    <div class="row">
                        <span>Fee: </span>
                        <span>{{ activeTo === 'YNP' ? '0.5%' : '2.5%' }}</span>
                    </div>
                    <div class="row">
                        <span>Final amount: </span>
                        <span>{{ Number((amountTo * (activeTo === 'YNP' ? 0.995 : 0.975)).toFixed(wallets[activeTo] ? wallets[activeTo].precision : 4)) }} {{activeTo}}</span>
                    </div>
                </div>
                <div class="form-row">
                    <button @click="makeExchange">Exchange</button>
                </div>
            </div>
        </div>
        <div class="two-cols" v-show="depositAddress && currency === 'BTC'">
            <div class="onecol">
                <h4 class="between">Buy Crypto <i class="material-icons hover-activate">help_outline</i>
                    <span class="hover-text">Please copy your BTC address from the block above and paste it in the destination BTC address when purchasing, this is a 3rd party service and we won't be able support an issue if a mistake was made using the "Buy Crypto" feature.</span>
                </h4>
                <div class="iframe-container">
                    <iframe src="https://buy.coingate.com/?utm_source=coingate&utm_medium=menu_button&utm_campaign=checkout"></iframe>
                </div>
            </div>
            <div class="onecol" style="visibility: hidden">
            </div>
        </div>
        <TransactionsTable/>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'
    import {SUPPORT_EMAIL} from '@/common/config'
    import {DEPOSIT_REQUEST, YUAN_EXCHANGE} from '@/store/actions.type'
    import TransactionsTable from '@/components/TransactionsTable'
    import CurrenciesSelect from '@/components/CurrenciesSelect'
    import {DEPOSIT_ADDRESS, NOTIFICATION_ERROR} from '@/store/mutations.type'
    import {currencySign} from '@/common/helpers'

    export default {
        name: "Deposit",
        components: {CurrenciesSelect, TransactionsTable},
        data() {
            return {
                supportEmail: SUPPORT_EMAIL,
                psp: 'nax',
                currency: 'BTC',
                amount: '',
                amountTo: '',
                active: 'BTC',
                activeTo: 'YNP',
                selectActive: false,
                selectActiveTo: false
            }
        },
        methods: {
            makeDeposit() {
                switch (this.psp) {
                    case 'nax':
                        this.$store.dispatch(DEPOSIT_REQUEST, {
                            psp: this.psp,
                            currency: this.currency,
                            account_id: this.wallets[this.currency.split('-')[0]].id,
                            product: this.wallets[this.currency.split('-')[0]].product
                        })
                        break
                }
            },
            swapActives() {
                let tmp = this.active

                this.active = this.activeTo
                this.activeTo = tmp
            },
            changeSymbol(symbol) {
                this.active = symbol
                this.selectActive = false
            },
            changeSymbolTo(symbol) {
                this.activeTo = symbol
                this.selectActiveTo = false
            },
            updateAmount(e) {
                let val = e.target.value

                this.amount = val
                this.amountTo = Number((val * this.symbols[this.active+'USD'].quote.bid / this.symbols[this.activeTo+'USD'].quote.bid).toFixed(8))
            },
            updateAmountTo(e) {
                let val = e.target.value

                this.amountTo = val
                this.amount = Number((val * this.symbols[this.activeTo+'USD'].quote.bid / this.symbols[this.active+'USD'].quote.bid).toFixed(this.wallets[this.active].precision))
            },
            setMax() {
                this.amount = this.wallets[this.active] ? (Math.floor(this.wallets[this.active].balance * Math.pow(10, this.wallets[this.active].precision) + Number.EPSILON) / Math.pow(10, this.wallets[this.active].precision) + Number.EPSILON).toFixed(this.wallets[this.active] ? this.wallets[this.active].precision : 2) : 0
            },
            makeExchange() {
                if (this.active === 'YNP') {
                    if (this.exchangeLimits[this.activeTo] < this.amount * this.symbols['YNPUSD'].quote.bid / this.symbols[this.activeTo+'USD'].quote.bid) {
                        this.$store.commit(NOTIFICATION_ERROR, 'Not enough limit for this exchange direction')
                        return
                    }
                }

                this.$store.dispatch(YUAN_EXCHANGE, {
                    amount: this.amount,
                    currency: this.active,
                    commission: this.activeTo === 'YNP' ? 0.005 : 0.025,
                    from: {
                        id: this.wallets[this.active].id,
                        product: 'savings'
                    },
                    to: {
                        id: this.wallets[this.activeTo].id,
                        product: 'savings'
                    }
                })
            },
            currencySign
        },
        computed: {
            ...mapGetters(['depositAddress', 'activeAccount', 'accounts', 'tokens', 'modals', 'symbols', 'accounts', 'exchangeLimits']),
            wallets() {
                let wallets = {}

                for(let n in this.accounts) {
                    if (this.accounts[n].alias === this.accounts[n].currency+' Wallet') {
                        wallets[this.accounts[n].currency] = this.accounts[n]
                    }
                }

                return wallets
            }
        },
        watch: {
            currency() {
                this.$store.commit(DEPOSIT_ADDRESS, '')
            },
            page(val) {
                if (val === 'deposit') {
                    this.active = 'BTC'
                    this.activeTo = 'YNP'
                    this.amount = ''
                    this.amountTo = ''
                }
            },
            active(val) {
                if (val === this.activeTo) {
                    this.activeTo = val === 'BTC' ? 'YNP' : 'BTC'
                }

                this.amount = ''
                this.amountTo = ''
            },
            activeTo(val) {
                if (val === this.active) {
                    this.active = val === 'BTC' ? 'YNP' : 'BTC'
                }

                this.amount = ''
                this.amountTo = ''
            }
        }
    }
</script>

<style scoped>
    img {
        width: 200px;
        height: 200px;
    }

    .deposit {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        flex: 1 1 auto;
    }

    h4 {
        width: 100%;
        position: relative;
    }

    .hover-text {
        display: none;
        position: absolute;
        top: 20px;
        background: var(--white);
        border: 1px solid rgba(0,0,0,0.1);
        border-radius: 10px;
        left: 80%;
        font-size: 14px;
        width: 300px;
        z-index: 4;
        padding: 15px;
    }

    .hover-activate:hover + .hover-text {
        display: flex;
    }

    /* Exchange */

    .input {
        border: 1px solid rgba(0,0,0,0.2);
        border-radius: 5px;
        padding: 5px 10px 5px 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 0 20px;
        cursor: pointer;
        position: relative;
    }

    .input img {
        width: 20px;
        height: 20px;
        margin-right: 5px;
    }

    .input input {
        border: none;
        flex: 1 1 auto;
    }

    button {
        background: var(--brand);
        border: 1px solid var(--brand);
        color: var(--background);
        font-size: 1.3em;
        font-weight: 500;
        border-radius: 5px;
        margin-top: 20px;
    }

    .disabled {
        background: var(--font-secondary);
    }

    .currency {
        font-size: 1.3em;
    }

    .max {
        font-size: 1.3em;
        color: var(--brand);
        cursor: pointer;
        margin-right: 10px;
    }

    .change-ico {
        display: flex;
        align-items: center !important;
        justify-content: center !important;
        margin: 0;
        cursor: pointer;
    }

    .change-ico i {
        font-size: 2.5em;
        color: var(--background-dark)
    }

    .exchange {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .exchange > div {
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }

    .available {
        display: flex;
        align-items: flex-end;
        margin: 20px 0 5px;
    }

    .available span:first-child {
        color: var(--font-secondary)
    }

    .available span:last-child {
        margin-left: 5px;
        font-weight: 500;
    }

    .fees .row span:first-child {
        color: var(--font-secondary)
    }

    .fees .row span:last-child {
        margin-left: 5px;
        font-weight: 500;
    }

    iframe {
        width: 100%;
        height: 600px;
        /*height: 1100px;*/
        /*margin-top: -500px*/
    }

    .iframe-container {
        width: 100%;
        height: 600px;
        overflow: hidden;
    }
</style>
