<template>
    <div class="settings">
        <div class="two-cols">
            <div class="onecol">
                <h4>Default Currency</h4>
                <div class="form-row">
                    <select v-model="currency">
                        <option value="CNY">Chinese Yuan</option>
                        <option value="USD">US Dollar</option>
                        <option value="EUR">Euro</option>
                        <option value="YNP">YuanPay</option>
                        <option value="RUB">Rouble</option>
                    </select>
                </div>
            </div>
            <div class="onecol noborder">
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'
    import {DISPLAY_CURRENCY} from '@/store/mutations.type'

    export default {
        name: "Settings",
        data() {
            return {
                currency: 'USD'
            }
        },
        computed: {
            ...mapGetters(['displayCurrency'])
        },
        watch: {
            currency(val) {
                this.$store.commit(DISPLAY_CURRENCY, val)
            },
            displayCurrency(val) {
                this.currency = val
            }
        }
    }
</script>

<style scoped>
    .settings {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        flex: 1 1 auto;
    }

    .noborder {
        border: none;
    }
</style>