<template>
    <div class="verification">
        <div class="two-cols">
            <div class="onecol">
                <h4>{{ tokens.UploadDocument }}</h4>
                <div class="form-row">
                    <label>{{ tokens.DocumentType }}</label>
                    <select v-model="type">
                        <option value="0">{{ tokens.ProofOfId }}</option>
                        <option value="1">{{ tokens.ProofOfAddress }}</option>
                    </select>
                </div>
                <div class="form-row">
                    <label>{{ tokens.File }}</label>
                    <input type="file" class="form-control-file" id="document_file">
                    <span class="text-muted">{{ tokens.HelpVerification1 }}</span>
                </div>
                <div class="form-row form-actions">
                    <button type="button" class="btn btn-primary" v-on:click="uploadDocument">{{ tokens.Upload }}</button>
                </div>
            </div>
            <div class="onecol extra-text">
                <i class="material-icons large">help_outline</i>
                <span class="text">{{ tokens.HelpVerification2 }} <a class="support-email" v-bind:href="'mailto:'+supportEmail">{{supportEmail}}</a> </span>
            </div>
        </div>
        <DocumentsTable/>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {SUPPORT_EMAIL} from '@/common/config'
import {DOCUMENT_UPLOAD} from "@/store/actions.type";
import DocumentsTable from '@/components/DocumentsTable'

export default {
    name: "Verification",
    components: {DocumentsTable},
    data() {
        return {
            type: 0,
            supportEmail: SUPPORT_EMAIL
        }
    },
    methods: {
        uploadDocument() {
            let data = new FormData()

            let inp = document.getElementById('document_file')

            data.append('data', JSON.stringify({
                type: parseInt(this.type)
            }))
            data.append('file', inp.files[0])

            this.$store.dispatch(DOCUMENT_UPLOAD, data)

            inp.value = ''
        }
    },
    computed: {
        ...mapGetters(['tokens'])
    }
}
</script>

<style scoped>
    .verification {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        flex: 1 1 auto;
    }
</style>
