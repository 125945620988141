<template>
    <div class="register" :class="{hide: page !== 'register'}">
        <div class="cont">
            <div class="title">Create YuanPay Wallet</div>
            <div class="form-row">
                <label>Full Name</label>
                <input v-model="fullname" />
            </div>
            <div class="form-row">
                <label>E-mail address</label>
                <input v-model="email" />
            </div>
            <div class="form-row">
                <label>Phone (International format)</label>
                <input v-model="phone" placeholder="+XXXXXXXXXXXXX" />
            </div>
            <div class="form-row">
                <label>Password</label>
                <input type="password" v-model="password" />
            </div>
            <div class="form-row">
                <label>Invitation Code (optional)</label>
                <input v-model="invitation" />
            </div>
            <div class="form-row terms-row">
                <input type="checkbox" v-model="agree" class="check" />
                <div class="terms">I agree to the <a href="https://yuanpaygroup.com/pages/terms.html" target="_blank">Terms and Conditions</a></div>
            </div>
            <div class="form-row">
                <button @click="register">Register</button>
            </div>
            <div class="login">
                <div>Already Registered? <span class="link" @click="openLogin">Log In</span> instead</div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'
    import {PAGE} from '@/store/mutations.type'
    import {COMPANY_NAME} from '@/common/config'
    import {REGISTER} from '@/store/actions.type'

    export default {
        name: "Register",
        data() {
            return {
                fullname: '',
                email: '',
                phone: '',
                password: '',
                invitation: '',
                agree: false,
                companyName: COMPANY_NAME
            }
        },
        methods: {
            openLogin() {
                this.$store.commit(PAGE, 'login')
            },
            register() {
                this.$store.dispatch(REGISTER, {
                    email: String(this.email).trim(),
                    phone: String(this.phone).trim(),
                    fullname: this.fullname,
                    password: this.password,
                    invitation: this.invitation
                })
                this.$store.commit(PAGE, 'login')
            }
        },
        computed: {
            ...mapGetters(['page'])
        },
        watch: {
            page(val) {
                if (val === 'register') {
                    this.step = 1
                    this.invitation = window.localStorage.getItem('invitation') || ''
                }
            }
        }
    }
</script>

<style scoped>
    .register {
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        overflow-y: scroll;
        z-index: 5;
        background: var(--background-dark);
        -webkit-box-shadow: inset 0px 0px 100px 0px rgba(255, 88, 88, 1);
        -moz-box-shadow: inset 0px 0px 100px 0px rgba(255,88,88,1);
        box-shadow: inset 0px 0px 100px 0px rgba(255,88,88,1);
    }

    .cont {
        background: var(--background);
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 450px;
        padding: 40px 25px;
        border-radius: 10px;
    }

    .title {
        font-size: 2em;
        font-weight: 400;
        margin-bottom: 30px;
    }

    .form-row {
        width: 100%;
        margin-bottom: 30px;
    }

    label {
        font-size: 1.2em;
    }

    input, textarea {
        border-radius: 5px;
        border: 1px solid rgba(0,0,0,0.2);
        padding: 10px;
    }

    textarea {
        resize: none;
        width: 100%;
        height: 100px;
        font-size: 1.4em;
        font-weight: 400;
        text-align: center;
        vertical-align: center;
    }

    button {
        background: var(--brand);
        border: 1px solid var(--brand);
        color: var(--background);
        font-size: 1.3em;
        font-weight: 500;
        border-radius: 5px;
    }

    .login {
        text-align: center;
        font-size: 1.2em;
        font-weight: 400;
    }

    .login .link {
        color: var(--font-blue);
        text-decoration: underline;
        cursor: pointer;
    }
</style>