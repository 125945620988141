<template>
    <div class="withdrawal">
        <div class="two-cols">
            <div class="onecol">
                <h4>{{ tokens.RequestWithdrawal }}</h4>
<!--                <div class="withdrawal-options">-->
<!--                    <div class="item" @click="method = 'btc'" :class="{active: method === 'btc'}">-->
<!--                        <img src="/images/crypto-icons/btc@2x.png" />-->
<!--                        <div class="col">-->
<!--                            <span class="name">Bitcoin</span>-->
<!--                            <div class="row fees">-->
<!--                                <div class="row">-->
<!--                                    <span>Fee:</span>-->
<!--                                    <span>0%</span>-->
<!--                                </div>-->
<!--                                <div class="row">-->
<!--                                    <span>Time:</span>-->
<!--                                    <span>10-60 min</span>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="item" @click="method = 'bank'" :class="{active: method === 'bank'}">-->
<!--                        <img src="/images/bank-deposit.png" />-->
<!--                        <div class="col">-->
<!--                            <span class="name">Wire Transfer</span>-->
<!--                            <div class="row fees">-->
<!--                                <div class="row">-->
<!--                                    <span>Fee:</span>-->
<!--                                    <span>4%</span>-->
<!--                                </div>-->
<!--                                <div class="row">-->
<!--                                    <span>Time:</span>-->
<!--                                    <span>3-5 days</span>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
                <div class="form-row">
                    <label>{{ tokens.Currency }}</label>
                    <select v-model="currency">
                        <option value="BTC">Bitcoin</option>
                        <option value="YNP">YuanPay</option>
                    </select>
                </div>
                <template v-if="currency === 'BTC'">
                    <div class="form-row">
                        <label>{{ tokens.Amount }}</label>
                        <input type="text" v-model="amount">
                        <span class="text-muted">{{ tokens.CurrentBalanceIs }} {{availableBalance}}. <span class="subaction" v-on:click="amount = Math.max(0, availableBalance).toFixed(wallets.BTC.precision)">{{ tokens.WithdrawAll }}</span></span>
                    </div>
                    <div class="form-row" v-bind:class="{hide: method !== 'btc'}">
                        <label>{{ tokens.BitcoinAddress }}</label>
                        <input type="text" v-model="methods.btc.wallet" :disabled="withdrawalAddress ? 'disabled' : false" :placeholder="withdrawalDeposited ? '' : 'Please make a BTC deposit first'">
                    </div>
                    <div class="form-row" v-bind:class="{hide: method !== 'bank'}">
                        <label>{{ tokens.AccountNumber }}</label>
                        <input type="text" v-model="methods.bank.account">

                    </div>
                    <div class="form-row" v-bind:class="{hide: method !== 'bank'}">
                        <label>{{ tokens.AccountHolder }}</label>
                        <input type="text" v-model="methods.bank.holder">

                    </div>
                    <div class="form-row" v-bind:class="{hide: method !== 'bank'}">
                        <label>{{ tokens.IBAN }}</label>
                        <input type="text" v-model="methods.bank.iban">

                    </div>
                    <div class="form-row" v-bind:class="{hide: method !== 'bank'}">
                        <label>{{ tokens.SWIFT }}</label>
                        <input type="text" v-model="methods.bank.swift">

                    </div>
                    <div class="form-row form-actions">
                        <button type="button" class="btn btn-primary" v-on:click="requestWithdrawal">{{ tokens.RequestWithdrawal }}</button>
                    </div>
                </template>
                <template v-if="currency === 'YNP'">
                    <div class="form-row">
                        <label>{{ tokens.Amount }}</label>
                        <input type="text" v-model="amount">
                        <span class="text-muted">{{ tokens.CurrentBalanceIs }} {{availableBalance}}. <span class="subaction" v-on:click="amount = Math.max(0, availableBalance).toFixed(wallets.YNP.precision)">{{ tokens.WithdrawAll }}</span></span>
                    </div>
                    <div class="form-row" v-bind:class="{hide: method !== 'btc'}">
                        <label>{{ tokens.YNPAddress }}</label>
                        <input type="text" v-model="methods.ynp.wallet">
                    </div>
                    <div class="form-row form-actions">
                        <button type="button" class="btn btn-primary" v-on:click="requestWithdrawalYnp">{{ tokens.RequestWithdrawal }}</button>
                    </div>
                </template>
            </div>
            <div class="onecol extra-text">
                <i class="material-icons large">help_outline</i>
                <span class="text">{{ tokens.HelpWithdrawal }} <a class="support-email" v-bind:href="'mailto:'+supportEmail">{{supportEmail}}</a> </span>
            </div>
        </div>
        <WithdrawalsTable/>
        <div class="modal-wrapper"
             :class="{hide: !confirmModal}"
             @click.stop="confirmModal = false">
            <div class="modal" @click.stop>
                <div class="title">
                    <span>Confirm Action</span>
                    <i class="material-icons" @click="confirmModal = false">close</i>
                </div>
                <div class="form-row">
                    <p>The BTC address <strong>{{ methods.btc.wallet }}</strong> will be linked to your account permanently. No any other address can be used for withdrawal expect this one in future. Do you confirm the action?</p>
                </div>
                <div class="row between">
                    <button class="btn btn-primary" @click="confirmedWithdrawal">Confirm</button>
                    <button class="btn btn-secondary" @click="confirmModal = false">Cancel</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {SUPPORT_EMAIL} from '@/common/config'
import {WITHDRAWAL_REQUEST, YUAN_WITHDRAWAL_ADDRESS_SET} from '@/store/actions.type'
import WithdrawalsTable from '@/components/WithdrawalsTable'
import {NOTIFICATION_ERROR} from '@/store/mutations.type'

export default {
    name: "Withdrawal",
    components: {WithdrawalsTable},
    mounted() {
        this.methods.btc.wallet = this.withdrawalAddress
    },
    data() {
        return {
            supportEmail: SUPPORT_EMAIL,
            amount: '',
            method: 'btc',
            currency: 'BTC',
            methods: {
                btc: {
                    wallet: ''
                },
                ynp: {
                    wallet: ''
                },
                bank: {
                    account: '',
                    holder: '',
                    iban: '',
                    swift: ''
                }
            },
            confirmModal: false
        }
    },
    methods: {
        requestWithdrawal() {
            if (this.amount <= 0 || isNaN(parseFloat(this.amount))) {
                this.$store.commit(NOTIFICATION_ERROR, 'Invalid withdrawal amount')
                return
            }

            if (!this.methods.btc.wallet && this.method === 'btc') {
                this.$store.commit(NOTIFICATION_ERROR, 'Please enter your BTC address')
                return
            }

            if (this.withdrawalAddress === '' && this.method === 'btc') {
                this.confirmModal = true
                return
            }

            let details = ''
            switch (this.method) {
                case 'btc':
                    details = this.methods.btc.wallet
                    break
                case 'bank':
                    details =
`Account: ${this.methods.bank.account}
Account holder: ${this.methods.bank.holder}
IBAN: ${this.methods.bank.iban}
SWIFT:  ${this.methods.bank.swift}`
                    break
            }

            this.$store.dispatch(WITHDRAWAL_REQUEST, {
                account_id: this.wallets.BTC.id,
                wallet: details,
                amount: this.amount
            })

            this.method = 'btc'
            this.amount = ''
            this.methods.bank.account = ''
            this.methods.bank.holder = ''
            this.methods.bank.iban = ''
            this.methods.bank.swift = ''

            this.confirmModal = false
        },
        requestWithdrawalYnp() {
            if (this.amount <= 0 || isNaN(parseFloat(this.amount))) {
                this.$store.commit(NOTIFICATION_ERROR, 'Invalid withdrawal amount')
                return
            }

            if (!this.methods.ynp.wallet && this.method === 'btc') {
                this.$store.commit(NOTIFICATION_ERROR, 'Please enter your YuanPay address')
                return
            }

            this.$store.dispatch(WITHDRAWAL_REQUEST, {
                account_id: this.wallets.YNP.id,
                wallet: this.methods.ynp.wallet,
                amount: this.amount
            })

            this.method = 'btc'
            this.currency = 'BTC'
            this.amount = ''
        },
        confirmedWithdrawal() {
            this.$store.dispatch(YUAN_WITHDRAWAL_ADDRESS_SET, this.methods.btc.wallet)

            this.$store.dispatch(WITHDRAWAL_REQUEST, {
                account_id: this.wallets.BTC.id,
                wallet: this.methods.btc.wallet,
                amount: this.amount
            })
        }
    },
    computed: {
        ...mapGetters(['activeAccount', 'tokens', 'withdrawalAddress', 'withdrawalDeposited', 'accounts']),
        wallets() {
            let ret = {
                BTC: {
                    balance: 0,
                    precision: 4
                },
                YNP: {
                    balance: 0,
                    precision: 4
                }
            }

            for(let n in this.accounts) {
                if (this.accounts[n].alias === 'BTC Wallet') {
                    ret.BTC = this.accounts[n]
                }
                if (this.accounts[n].alias === 'YNP Wallet') {
                    ret.YNP = this.accounts[n]
                }
            }

            return ret
        },
        availableBalance() {
            return Number((Math.floor(this.wallets[this.currency].balance * Math.pow(10, this.wallets[this.currency].precision)) / Math.pow(10, this.wallets[this.currency].precision)).toFixed(this.wallets[this.currency].precision))
        }
    },
    watch: {
        withdrawalAddress(val) {
            this.methods.btc.wallet = val
        }
    }
}
</script>

<style scoped>
    .withdrawal {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        flex: 1 1 auto;
    }

    .withdrawal-options {
        display: flex;
        align-items: stretch;
        height: 60px;
        margin-bottom: 40px;
    }

    .withdrawal-options .item {
        display: flex;
        padding: 8px 20px;
        border-radius: 5px;
        border: 1px solid var(--brand);
        align-items: center;
        flex: 1 1 auto;
        margin-right: 30px;
        cursor: pointer;
    }

    .withdrawal-options .item.active {
        background: var(--brand);
        color: var(--white)
    }

    .withdrawal-options .item:last-child {
        margin-right: 0
    }

    .withdrawal-options .item img {
        height: 40px;
        width: 40px;
        margin-right: 15px;
    }

    .withdrawal-options .name {
        font-weight: 500;
        font-size: 1.4em;
        margin-bottom: 5px;
    }

    .withdrawal-options .fees .row {
        margin-right: 10px;
    }

    .withdrawal-options .item .fees .row span:first-child {
        color: var(--font-secondary);
        margin-right: 5px;
    }

    .withdrawal-options .item.active .fees .row span:first-child {
        color: var(--white)
    }

    .withdrawal-options .item .fees .row span:last-child {
        color: var(--font);
        font-weight: 400;
    }

    .withdrawal-options .item.active .fees .row span:last-child {
        color: var(--white);
        font-weight: 500;
    }

    .modal {
        padding: 15px 20px;
    }

    .title {
        font-size: 1.3em;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: var(--font-blue);
        margin-bottom: 20px;
    }

    .title i {
        font-size: 1.3em;
        cursor: pointer;
    }

    p {
        font-size: 1.2em;
        line-height: 1.5;
    }
</style>
