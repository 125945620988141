<template>
    <div class="login" :class="{hide: page !== 'login'}">
        <div class="cont">
            <div class="title">Welcome Back!</div>
            <div class="form-row" :class="{hide: !!loginData.email}">
                <label>E-mail address</label>
                <input v-model="email" @keyup.enter="login" />
            </div>
            <div class="form-row" :class="{hide: !!loginData.email}">
                <label>Password</label>
                <input type="password" v-model="password" @keyup.enter="login" />
            </div>
            <div class="form-row" :class="{hide: !loginData.email}">
                <label>2FA Code</label>
                <input type="text" v-model="otp" @keyup.enter="login" />
            </div>
            <div class="form-row">
                <button @click="login">Log In</button>
            </div>
            <div class="signup" :class="{hide: !loginFailed}">
                <div>Incorrect login details.<br/><span class="link" @click="openForgot">Reset your password</span> or <span class="link" @click="openRegister">create a new account</span></div>
            </div>
            <vue-telegram-login
                mode="callback"
                telegram-login="YuanPayAuthBot"
                @callback="tgCallback" />
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'
    import {LOGIN_FAILED, PAGE} from '@/store/mutations.type'
    import {LOGIN, TELEGRAM_LOGIN} from '@/store/actions.type'
    import {vueTelegramLogin} from 'vue-telegram-login'

    export default {
        name: "Login",
        components: {
            vueTelegramLogin
        },
        data() {
            return {
                email: '',
                password: '',
                otp: ''
            }
        },
        methods: {
            openRegister() {
                this.$store.commit(PAGE, 'register')
            },
            openForgot() {
                this.$store.commit(PAGE, 'forgot-password')
            },
            login() {
                this.$store.dispatch(LOGIN, {
                    email: this.email,
                    password: this.password,
                    otp: this.otp
                })
            },
            tgCallback(user) {
                this.$store.dispatch(TELEGRAM_LOGIN, user)
            }
        },
        computed: {
            ...mapGetters(['page', 'loginData', 'loginFailed'])
        },
        watch: {
            page(val) {
                if (val === 'login') {
                    this.email = ''
                    this.password = ''
                    this.otp = ''
                    this.$store.commit(LOGIN_FAILED, false)
                }
            }
        }
    }
</script>

<style scoped>
    .login {
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        overflow-y: scroll;
        z-index: 5;
        background: var(--background-dark);
        -webkit-box-shadow: inset 0px 0px 100px 0px rgba(255, 88, 88, 1);
        -moz-box-shadow: inset 0px 0px 100px 0px rgba(255,88,88,1);
        box-shadow: inset 0px 0px 100px 0px rgba(255,88,88,1);
    }

    .cont {
        background: var(--background);
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 450px;
        padding: 40px 25px;
        border-radius: 10px;
    }

    .title {
        font-size: 2em;
        font-weight: 400;
        margin-bottom: 30px;
    }

    .form-row {
        width: 100%;
        margin-bottom: 30px;
    }

    label {
        font-size: 1.2em;
    }

    input {
        border-radius: 5px;
        border: 1px solid rgba(0,0,0,0.2);
        padding: 10px;
    }

    button {
        background: var(--brand);
        border: 1px solid var(--brand);
        color: var(--background);
        font-size: 1.3em;
        font-weight: 500;
        border-radius: 5px;
    }

    .signup {
        text-align: center;
        font-size: 1.2em;
        font-weight: 400;
        margin-top: 10px;
    }

    .signup .link {
        color: var(--font-blue);
        text-decoration: underline;
        cursor: pointer;
    }

    .signup div {
        color: var(--red)
    }
</style>